import fetch from 'isomorphic-unfetch';

export default class HttpClient {
    constructor(url, isOnServer = false) {
        this.url = url;
        this.isOnServer = isOnServer;
    }

    post = async (data, headers = {}, authToken = null) => {
        const defaultHeaders = {
            "Content-Type": "application/json; charset=utf-8",
            'Accept': 'application/json'
        };

        headers = { ...defaultHeaders, ...headers };

        if (authToken) {
            headers.Authorization = "Bearer " + authToken;
        }

        const options = {
            method: "POST",
            mode: "cors",
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "same-origin", // include, same-origin, *omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        };

        // Handle server side requests on development machine to prevent self-signed certificate refection.
        if (this.isOnServer && !process.env.NODE_TLS_REJECT_UNAUTHORIZED) {
            const https = require("https");
            options.agent = new https.Agent({
                rejectUnauthorized: false
            });
        }

        // Doesn't handle self-signed certificates.
        const response = await fetch(this.url, options);
        return await response.json();
    }

    upload = async (data, headers = {}, authToken = null) => {

        const defaultHeaders = {
            //'Content-Type': 'application/x-www-form-urlencoded',
           // 'Content-Type': 'multipart/form-data', // Doesn't work for some reason.
           'Accept': 'application/json'
        };

        headers = { ...defaultHeaders, ...headers };

        if (authToken) {
            headers.Authorization = "Bearer " + authToken;
        }

        const options = {
            method: "POST",
            mode: "cors",
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "same-origin", // include, same-origin, *omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: data // body data type must match "Content-Type" header
        };

        // Handle server side requests on development machine to prevent self-signed certificate refection.
        if (this.isOnServer && !process.env.NODE_TLS_REJECT_UNAUTHORIZED) {
            const https = require("https");
            options.agent = new https.Agent({
                rejectUnauthorized: false
            });
        }

        // Doesn't handle self-signed certificates.
        const response = await fetch(this.url, options);
        return await response.json();
    }
}
