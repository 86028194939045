import React from "react";
import { toast } from "react-toastify";
import TipsApi from "../../Core/Api/TipsApi";
import { dateToStr } from "../../Core/Utils";
import Layout from "../../Layout/Layout";
import FormTopMenu from "../../Components/FormTopMenu";

class TipCreate extends React.Component {

  constructor(props) {
    super(props);

    this.createNewTip((tipId) => {
      props.history.push(`/tip/${tipId}/`);
    });
  }

  render = () => 
    <Layout bg="light">
      <FormTopMenu items={
        [
          {
            action: () => {
              this.props.history.push('/tips');
            },
            label: 'BACK'
          }
        ]
      } />
      <main className="normal-container my-5">
        <div className="px-5">
          Creating...
        </div>
      </main>
    </Layout>

  createNewTip = (next) => {
    const tip = {
      title: 'Untitled tip',
      published_at: dateToStr(new Date())
    }

    TipsApi.createTip(tip)
    .then(data => {
      if (data.data.createTip) {
        next.call(null, data.data.createTip.id);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        toast.error("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      toast.error("An error occured.");
    });
  }
}

export default TipCreate;
