import React from "react";
import { toast } from "react-toastify";
import FormTopMenu from "../../Components/FormTopMenu";
import FavoritesApi from "../../Core/Api/FavoritesApi";
import { dateToStr } from "../../Core/Utils";
import Layout from "../../Layout/Layout";

class FavoriteCreate extends React.Component {

  constructor(props) {
    super(props);

    this.createNewFavorite((favoriteId) => {
      props.history.push(`/favorite/${favoriteId}/`);
    });
  }

  render = () => 
    <Layout bg="light">
      <FormTopMenu items={
        [
          {
            action: () => {
              this.props.history.push('/favorites');
            },
            label: 'BACK'
          }
        ]
      } />
      <main className="normal-container my-5">
        <div className="px-5">
          Creating...
        </div>
      </main>
    </Layout>

  createNewFavorite = (next) => {
    const favorite = {
      title: 'Untitled favorite',
      published_at: dateToStr(new Date())
    }

    FavoritesApi.createFavorite(favorite)
    .then(data => {
      if (data.data.createFavorite) {
        next.call(null, data.data.createFavorite.id);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        toast.error("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      toast.error("An error occured.");
    });
  }
}

export default FavoriteCreate;
