import React from 'react';
import { Link } from 'react-router-dom';
import FormTopMenu from '../../Components/FormTopMenu';
import Layout from '../../Layout/Layout';
import BlocksApi from '../../Core/Api/BlocksApi';

export default class Blocks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks: []
    };
  }
  
  componentDidMount = () => {
    this.fetchBlocks();    
  };

  render = () => {
    const { blocks } = this.state;

    return (    
      <Layout>
        <FormTopMenu items={[
            { action: () => this.props.history.push('/'), label: 'BACK' }
        ]} />
        <main 
          className="my-5 mx-auto w-100 text-center d-flex flex-column justify-content-center align-items-center" 
          style={{maxWidth: "600px", height: "100%"}}
        >
          <div className="d-flex flex-column" style={{width: "80%"}}>
            {blocks.map(block => 
              <Link key={block.id} className="btn btn-lg btn-secondary btn-block my-2" to={`/blocks/${block.id}`}>
                {block.name}
              </Link>
            )}
          </div>
        </main>
      </Layout>
    )
  }

  fetchBlocks = () => {
    BlocksApi.fetchBlocks()
    .then(data => {
      if (data.data.blocks && data.data.blocks.length > 0) {
        const blocks = data.data.blocks;
        this.setState({blocks});
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }
}
