import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'react-id-swiper';
// import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import "react-id-swiper/src/styles/css/swiper.css";
import { EffectFade } from "swiper/dist/js/swiper.esm";
import { getNumSlidesPerView, pageSize } from '../Core/ResponsiveUtils';
import { ReactComponent as NextIcon } from '../Icons/player/arrow-next.svg';
import { ReactComponent as PreviousIcon } from '../Icons/player/arrow-prev.svg';
import Page from './Page';
import styles from "./StoryPlayer.module.scss";

class StoryPlayer extends React.Component {

  static defaultProps = {
    topMargin: 0
  }

  static propTypes = {
    pages: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    topMargin: PropTypes.number // top margin on mobiles, i.e. the height of the header.
  };

  constructor(props) {
    super(props);

    const {width, height} = this.screenSize();

    this.state = {
      pageSize: pageSize(width, height),
      slidesPerView: getNumSlidesPerView(width, height),
      isPreviousButtonVisible: false,
      isNextButtonVisible: false,
      pager: '',
      fullscreenDetected: false,
      orientationChangeDetected: false
    }

    this.pages = props.pages;
    this.pagesDesktop = this.reformatPagesForDesktops(this.pages); // @todo: Move the function call to componentDidMount

    this.totalPages = this.pages.length;

    this.swiper = null;

    this.footerHeight = 40;

    this.swiperParams = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      shouldSwiperUpdate: true,
      rebuildOnUpdate : true,
      activeSlideKey: 0,
      effect: 'fade',
      on: {
        init: this.onSwiperInit,
        slideChange: this.onSwiperSlideChange,
        observerUpdate: this.onSwiperObserverUpdate
      },
      observer: true,
      modules: [EffectFade],
      getSwiper: this.updateSwiper,
    };

    this.fullscreenElementName = this.getFullscreenElementName();
  }

  addFullscreenChangeHandler = () => {
  
    if (this.fullscreenElementName == null) {
      return;
    }

    document.addEventListener(this.fullscreenElementName, this.setFullScreenState, false);
  }

  setFullScreenState = () => {
    this.setState({ fullscreenDetected: document[this.fullscreenElementName] });
  }

  getFullscreenElementName = () => {
    if ("fullscreenElement" in document) {
      return "fullscreenElement";
    }
    if ("msFullscreenElement" in document) {
      return "msFullscreenElement";
    }
    if ("mozfullscreenchange" in document) {
      return "mozfullscreenchange";
    }
    if ("webkitIsFullScreen" in document) {
      return "webkitIsFullScreen";
    }

    return null;
  }

  removeFullscreenChangeHandler = () => {
    if (this.fullscreenElementName == null) {
      return;
    }

    document.removeEventListener(this.fullscreenElementName, this.setFullScreenState);
  }

  orientationChangeHandler = () => {
    this.setState({ orientationChangeDetected:  true });
  }

  componentDidMount = () => {
    this.adaptToScreenSize();

    window.addEventListener("orientationchange", this.orientationChangeHandler);
    this.addFullscreenChangeHandler();
    window.addEventListener('resize', this.adaptToScreenSize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.adaptToScreenSize);
    this.removeFullscreenChangeHandler();
    window.removeEventListener('orientationchange', this.orientationChangeHandler);
  }

  render() {
    const {
      pageSize, 
      slidesPerView, 
      isNextButtonVisible,
      isPreviousButtonVisible,
      pager
    } = this.state;

    const {topMargin, id} = this.props;    
    const {width, height} = pageSize;

    const playerWidth = width * slidesPerView;

    const additionalPlayerStyles = {
      width: playerWidth
    }

    // For desktop, content height is only reduced by footer.
    let contentHeight = height - this.footerHeight;
 
    // For mobiles, content height is reduced by header and footer.
    if (slidesPerView === 1) {
      contentHeight = contentHeight - topMargin;

      additionalPlayerStyles.height = 
        additionalPlayerStyles.minHeight = 
        contentHeight;
    } 

    const leftPageRightBorderStyle = (slidesPerView === 2) ? '1px solid #cccccc' : 'none';

    return (
      <div className={styles.player} style={additionalPlayerStyles}>
        {slidesPerView === 1 &&
          <Swiper {...this.swiperParams}>
            {this.pages.map((page, index) => 
                <div key={index} className={styles['two-pages']} style={{width: playerWidth}}>
                  <article key={index} className={styles.page} style={{width, height: contentHeight}}>
                    <Page {...page} 
                      width={width} 
                      height={contentHeight} 
                      storyId={id} 
                      editMode={false} 
                    />
                  </article>
                </div>
            )}
          </Swiper>
        }
        {slidesPerView === 2 &&
          <Swiper {...this.swiperParams}>
            {this.pagesDesktop.map((twoPages, index) => 
                <div key={index} className={styles['two-pages']} style={{width: playerWidth}}>
                  <article className={styles.page} style={{width, height: contentHeight, borderRight: leftPageRightBorderStyle}}>
                    <Page {...twoPages.left} 
                      width={width} 
                      height={contentHeight} 
                      storyId={id} 
                      editMode={false} 
                    />
                  </article>
                  {twoPages.right !== undefined &&
                    <article className={styles.page} style={{width, height: contentHeight}}>
                      <Page {...twoPages.right} 
                        width={width} 
                        height={contentHeight} 
                        storyId={id} 
                        editMode={false} 
                      />
                    </article>
                  }
                </div>
              )
            }
          </Swiper>
        }

        <div className={styles['bottom-panel']}>
          <div className={styles['vertical-rhythm']}>
            <div></div>
            <div>{pager}</div>
          </div>
        </div>

        {isPreviousButtonVisible && 
          <button className={styles['prev-btn']} onClick={this.goPrev}>
            <PreviousIcon />
          </button>
        }
        {isNextButtonVisible &&
          <button className={styles['next-btn']} onClick={this.goNext}>
            <NextIcon />
          </button>
        }
      </div>
    )
  }

  updateSwiper = swiper => this.swiper = swiper;

  onSwiperInit = () => {
    this.setState({
      pager: this.pager()
    });
  }

  onSwiperSlideChange = () => {

    const {isPreviousButtonVisible, isNextButtonVisible} = this.state;

    this.setState({
      pager: this.pager()
    });

    const newPreviousButtonState = this.isPreviousButtonVisible();
    const newNextButtonState = this.isNextButtonVisible();

    if (
      isPreviousButtonVisible !== newPreviousButtonState ||
      isNextButtonVisible !== newNextButtonState
    ) {
      this.setState({
        isPreviousButtonVisible: newPreviousButtonState,
        isNextButtonVisible: newNextButtonState,
      });
    }
  }

  onSwiperObserverUpdate = () => {
    // We load pages dynamically, so we can't use init to show/hide control buttons 
    // depening on the number of pages in init().
    const newPreviousButtonState = this.isPreviousButtonVisible();
    const newNextButtonState = this.isNextButtonVisible();

    this.setState({
      isPreviousButtonVisible: newPreviousButtonState,
      isNextButtonVisible: newNextButtonState,
      pager: this.pager()
    });
  }

  screenSize = () => {
    return {
      // We should use device width to not depend on the width on the content 
      // becuase the content can overflow its container in some cases and forcefully increase the viewport width.
      width: window.screen.availWidth,
      // We should use window height here to take into account browser's top and bottom bars.
      height: window.innerHeight //window.screen.availHeight;
    }
  }

  adaptToScreenSize = () => {
    // Do nothing if fullscreen or orientation change detected.
    if (this.state.fullscreenDetected || this.state.orientationChangeDetected) {
      return;
    }

    const {width, height} = this.screenSize();

    this.setState({ 
      pageSize: pageSize(width, height),
      slidesPerView: getNumSlidesPerView(width, height),
      isNextButtonVisible: this.isNextButtonVisible(),
      isPreviousButtonVisible: this.isPreviousButtonVisible()
    });

    // Every time the screen is resized we check whether current page is the first and switch to it if not.
    if (this.swiper !== null && this.getCurrentPageIndex() !== 0) {
      this.swiper.slideTo(0, 0);
      // this.swiper.update();
    }
  }

  getCurrentPageIndex = () => {
    if (this.swiper === null) {
      return 0;
    }

    return this.swiper.activeIndex;
  }

  isPreviousButtonVisible = () => {

    if (this.state.slidesPerView !== 2) {
      return false;
    }

    return this.getCurrentPageIndex() > 0;
  }

  isNextButtonVisible = () => {

    if (this.state.slidesPerView !== 2) {
      return false;
    }

    let pagesNumber = this.pagesDesktop.length;

    return (pagesNumber > this.getCurrentPageIndex() + 1);
  }

  /**
   * Generate pager string.
   */
  pager = () => {
    const slidesPerView = this.state.slidesPerView;
    const currentPageIndex = this.getCurrentPageIndex();
    const totalPages = this.totalPages;
        
    // Default pager for mobiles.
    let pager = (currentPageIndex + 1) + ' / ' + totalPages;

    // Pager for desktops.
    if (slidesPerView === 2) {
      const firstPageNumber = currentPageIndex * 2 + 1;
      const secondPageNumber = firstPageNumber + 1;

      if (firstPageNumber === totalPages) {
        pager = firstPageNumber + ' / ' + totalPages;
      } else {
        pager = firstPageNumber + '-' + secondPageNumber + ' / ' + totalPages;
      }      
    }

    return pager;
  }

  /*
   * Reformat pages for desktop.
   * The structure should be:
   * [
   *  {left: page, right: pages}
   * ] 
   */
  reformatPagesForDesktops = (pages) => {
    let pagesDesktop = [];

    let left = true;
    let newIndex = 0;
    for (let i = 0; i < pages.length; i++) {
      if (left) {
        pagesDesktop[newIndex] = {
          left: pages[i]
        };
        left = false;
      } else {
        pagesDesktop[newIndex].right = pages[i];
        left = true;
        newIndex++;
      }
    }
    
    return pagesDesktop;
  }

  goNext = () => {
    if (this.swiper === null) {
      return;
    }

    this.swiper.slideNext();
  }

  goPrev = () => {
    if (this.swiper === null) {
      return;
    }
 
    this.swiper.slidePrev();
  }
}

export default StoryPlayer;
