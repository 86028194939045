// @see _variables.scss
const xs = {
    width: 250,
    height: 375
}

// const sm = {
//     width: 300,
//     height: 450
// }

// const lg = {
//     width: 400,
//     height: 600
// }

// const xl = {
//     width: 440,
//     height: 660
// }

// Bootstrap 4 breakpoints.
const breakpoints =  {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140
};

export function sizeToBreakpoint(viewportWidth, viewportHeight) {
        
    let breakpoint = '';
    if (viewportWidth >= 0 && viewportWidth < breakpoints.sm) {
        breakpoint = 'xs';
    }
    if (viewportWidth >= breakpoints.sm && viewportWidth < breakpoints.md) {
        breakpoint = 'sm';
    }
    if (viewportWidth >= breakpoints.md && viewportWidth < breakpoints.lg) {
        breakpoint = 'md';
    }
    if (viewportWidth >= breakpoints.lg && viewportWidth < breakpoints.xl) {
        breakpoint = 'lg';
    }
    if (viewportWidth >= breakpoints.xl) {
        breakpoint = 'xl';
    }
    return breakpoint;
}

export function pageSize(viewportWidth, viewportHeight) {
    viewportWidth = parseInt(viewportWidth);
    viewportHeight = parseInt(viewportHeight);

    const aspectRatio = 2/3;

    let size = xs;

    size = {
        width: viewportWidth,
        height: viewportHeight
    }

    if (viewportWidth >= breakpoints.sm) {
    //     size = sm;
    // }
    // if (viewportWidth >= breakpoints.lg) {
    //     size = lg;
    // }
    // if (viewportWidth >= breakpoints.xl) {
        // Take into account the header and margins at the top and bottom of the player.
        const topSpace = 40 + 25 * 2;
        let height = viewportHeight - topSpace;
        // Keep 2/3 aspect ration of stories.
        let width = height * aspectRatio; 
        
        // Take into account the control arrows for two-page view.
        const arrowsWidth = 34 * 2; 

        // If the size of two pages is greater than the available viewport width, 
        // consider width as a basis and reduce the width and height accordingly.
        if ((width * 2 + arrowsWidth) > viewportWidth) {
            width = (viewportWidth - arrowsWidth) / 2
            height = width / aspectRatio;
        }

        size = {
            width,
            height
        };
    }

    return size;
}

export function getNumSlidesPerView(viewportWidth, viewportHeight) {
    viewportWidth = parseInt(viewportWidth);
    viewportHeight = parseInt(viewportHeight);

    let result = 1;
    if (viewportWidth >= breakpoints.sm) {
        result = 2;
    }

    return result;
}
