import React from "react";
import Calendar from 'react-calendar';
import { toast } from "react-toastify";
import { CustomInput, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import FormTopMenu from "../Components/FormTopMenu";
import StoryCategorySelect from '../Components/StoryCategorySelect';
import { coverPageLayoutTypes, DEFAULT_COUNTRY, DEFAULT_COVER_PAGE_LAYOUT, DEFAULT_COVER_PAGE_SUBTITLE_SIZE, DEFAULT_COVER_PAGE_TITLE_SIZE, storyStatus } from "../config";
import CommonApi from "../Core/Api/CommonApi";
import StoriesApi from "../Core/Api/StoriesApi";
import { dateToStr, strToDate } from '../Core/Utils';
import Layout from "../Layout/Layout";
import "./StoryEdit1.scss";

const DEFAULT_STORY_WEIGHT = 1;

class StoryEdit1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id ? props.match.params.id : '',
      title: '',
      subtitle: '',
      body: '',
      status: storyStatus.Draft,
      created_at: '',
      published_at: new Date(),
      weight: DEFAULT_STORY_WEIGHT,
      categories: [],
      country_code: DEFAULT_COUNTRY,
      city: '',
      meta_description: '',
      allCountries: [],
      displayPublishConfirmation: false,
      error: '',
      cover_layout: DEFAULT_COVER_PAGE_LAYOUT,
      cover_title_size: DEFAULT_COVER_PAGE_TITLE_SIZE,
      cover_subtitle_size: DEFAULT_COVER_PAGE_SUBTITLE_SIZE
    };

    this.q = null;
  }

  componentDidMount = () => {

    this.fetchCountries();

    // If the story is new, don't try to load it from the remote source.
    if (!this.state.id) {
      return;
    }

    this.fetchStory(this.state.id);
  };

  render = () => {

    const {
      id,
      title,
      subtitle,
      categories,
      published_at,
      weight,
      country_code,
      allCountries,
      city,
      meta_description,
      cover_layout,
      cover_title_size,
      cover_subtitle_size
    } = this.state;

    return (
      <Layout bg="light">
        <FormTopMenu items={[
            { action: this.handleSaveClick, label: !id ? "SAVE DRAFT" : "UPDATE" },
            { action: this.handleContinueClick, label: "CONTINUE >" }
        ]} />

        <main className="normal-container mb-5 mt-2">
          <Form className="p-3">
            <Input id="id" name="id" type="hidden" defaultValue={id} />
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Title"
                defaultValue={title}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="subtitle">Subtitle</Label>
              <Input
                type="text"
                name="subtitle"
                id="subtitle"
                placeholder="Subtitle"
                defaultValue={subtitle}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText color="muted">For Cover page only.</FormText>
            </FormGroup>
            <StoryCategorySelect
              defaultValue={categories}
              onChange={this.handleCategoriesChange}
              name="published_at"
            />
            <FormGroup>
              <Label for="published_at">Date</Label>
              <Calendar
                name="published_at"
                onChange={this.handleDateChange}
                value={published_at}
                locale="es"
                className="calendar"
              />
            </FormGroup>
            <FormGroup>
              <Label for="weight">Order</Label>
              <Input
                type="number"
                name="weight"
                id="weight"
                placeholder="Weight"
                value={weight}
                min={DEFAULT_STORY_WEIGHT}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText>If there are multiple stories published on the same date, you may set their order through this field.</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="country_code">Country</Label>
              <Input
                type="select"
                name="country_code"
                id="country_code"
                value={country_code}
                onChange={this.handleInputChange}
                placeholder="Select..."
                required={true}
              >
                {allCountries.map(item => (
                  <option value={item.code} key={item.code}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                defaultValue={city}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <Label><b>Cover page</b></Label><br/>

              <Label>Layout</Label>
              <div className="cover-page-layout-selector d-flex flex-row flex-wrap justify-content-center p-2 px-2">
                {coverPageLayoutTypes().map(layout => (
                  <div
                    key={layout.name}
                    className="cover-layout m-1 m-md-2 text-center"
                  >
                    <input
                      type="radio"
                      id={layout.name}
                      name="cover_layout"
                      value={layout.name}
                      checked={layout.name === cover_layout}
                      onChange={this.handleLayoutChange.bind(null, layout.name)}
                    />
                    <Label for={layout.name}>
                      <img
                        width="80"
                        src={`/img/CoverPageLayouts/${layout.name}.png`}
                        alt=""
                      />
                      <div className="layout-name">{layout.name}</div>
                    </Label>
                  </div>
                ))}
              </div>

              <Label>Title size ( {`${cover_title_size}px`} )</Label>
              <CustomInput
                type="range"
                id="cover_title_size"
                name="cover_title_size"
                onChange={this.handleInputChange}
                value={cover_title_size}
              />

              <Label>Subtitle size ( {`${cover_subtitle_size}px`} )</Label>
              <CustomInput
                type="range"
                id="cover_subtitle_size"
                name="cover_subtitle_size"
                onChange={this.handleInputChange}
                value={cover_subtitle_size}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <FormGroup>
              <Label for="meta_description">Meta Description</Label>
              <Input
                type="textarea"
                name="meta_description"
                id="meta_description"
                placeholder=""
                value={meta_description}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText>Recommended length: 50-160 characters</FormText>
            </FormGroup>

          </Form>
        </main>
      </Layout>
    );
  };

  handleLayoutChange = (layoutName) => {
    const coverPageLayout = coverPageLayoutTypes()
        .find(layout => layout.name === layoutName);

    this.setState({
      cover_layout: layoutName,
      cover_title_size: coverPageLayout.size.title,
      cover_subtitle_size: coverPageLayout.size.subtitle
    });
  }

  handleDateChange = date => {
    this.setState({
      published_at: date
    });
  }

  handleInputChange = event => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleInputKeyDown = event =>  {
    // We have to handle Backspace for inputs additionally.
    if (event.keyCode === 8) {
     this.handleInputChange(event);
    }
  }

  handleCategoriesChange = (data) => {
    this.setState({
      'categories': data
    });
  };

  handleSaveClick = () => {

    const story = Object.assign({}, this.state);
    story.published_at = dateToStr(story.published_at, "yyyy-MM-dd 00:00:00");

    if (!story.id) {
        this.createStory(story);
    } else {
        this.updateStory(story);
    }
  };

  handleContinueClick = () => {
    const story = Object.assign({}, this.state);
    story.published_at = dateToStr(story.published_at, "yyyy-MM-dd 00:00:00");

    if (!story.id) {
        this.createStory(story, true);
    } else {
        this.updateStory(story, true);
    }
  };

  createStory = (story, redirectToPages) => {
    StoriesApi.createStory(story)
    .then(data => {
      if (data.data.createStory) {
        const state = data.data.createStory;

        if (state.published_at) {
          state.published_at = strToDate(state.published_at);
        }

        this.setState(state);

        this.props.history.push(redirectToPages ? `/story/${state.id}/pages/` : '/stories');
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  updateStory = (story, redirectToPages) => {
    StoriesApi.updateStory(story)
        .then(data => {
          if (data.data.updateStory) {
            const state = data.data.updateStory;

            if (state.published_at) {
              state.published_at = strToDate(state.published_at);
            }
            
            this.setState(state);

            this.props.history.push(redirectToPages ? `/story/${state.id}/pages/` : '/stories');
          } else {
            // @todo: Handle errors better.
            console.warn(data);
            this.reportError("An error occured.");
          }
        })
        .catch(err => {
          // @todo: Handle errors better.
          console.warn(err);
          this.reportError("An error occured.");
        });
  }

  fetchCountries = () => {
    CommonApi.fetchCountries()
    .then(data => {
      if (data.data.countries && data.data.countries.length > 0) {
        this.setState({
          'allCountries': data.data.countries
        });
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  fetchStory = (id) => {
    StoriesApi.fetchStory(id)
    .then(data => {
      if (data.data.stories && data.data.stories.length > 0) {
        const story = data.data.stories[0];

        story.categories = story.categories.map(item => {
          return item.id;
        });

        if (story.country_code === null) {
          story.country_code = '';
        }

        if (story.published_at) {
          story.published_at = strToDate(story.published_at);
        }

        this.setState(story);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  reportError = message => {
    toast.error(message)
  };
}

export default StoryEdit1;
