import React from "react";
import PropTypes from "prop-types";

class TextBoxView extends React.Component {
  static defaultProps = {
    body: "",
    editMode: true
  };

  static propTypes = {
    onTextEdit: PropTypes.func.isRequired,
    body: PropTypes.string,
    editMode: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.textBox = null;

    this.state = {
      hasScroll: false
    };
  }

  setRef = node => (this.textBox = node);

  componentDidMount() {
    this.setState({ 
      hasScroll: this.textBox.scrollHeight > this.textBox.clientHeight
    });
  }

  render() {
    const { editMode, body, onTextEdit } = this.props;
    const { hasScroll } = this.state;

    const defaultContent = editMode ? "Tap to edit" : "";
    const content = body ? body : defaultContent;

    let classes = "text-container";

    if (editMode) {
      classes += " edit";
    }

    if (hasScroll) {
      classes += " has-scroll";
    }

    const onClick = editMode ? onTextEdit : null;

    return (
      <div
        className={classes}
        dangerouslySetInnerHTML={{ __html: content }}
        ref={this.setRef}
        onClick={onClick}
      />
    );
  }
}

export default TextBoxView;
