export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_URL = process.env.SITE_URL;
export const DEFAULT_COUNTRY = '';

export const storyStatus = {
  Draft: 0,
  Published: 1
}

export const COVER_PAGE_LAYOUT = 'COVER';

export const favoriteStatus = {
  Draft: 0,
  Published: 1
}

// I've put it in constants to reduce the number of additional requests.
// Feel free to merge the category query with favorites GraphQL query (batching in GraphQL) 
// to get the list from the server instead.
export const FAVORITE_CATEGORIES = [
  { value: 1, label: 'Hoteles' },
  { value: 2, label: 'Restaurantes' },
  { value: 3, label: 'Lugares' },
];

export const tipStatus = {
  Draft: 0,
  Published: 1
}

export function layoutTypes() {
  const imageWidth = 250;
  const imageHeight = 375;

  // Please note the image (size) property is used in the image upload component, 
  // so it's important for layouts with images.

  return [
    {
      name: COVER_PAGE_LAYOUT,
      image: {
        width: imageWidth,
        height: imageHeight
      }
    },
    {
      name: 'TEXT'
    },
    {
      name: 'IMAGE',
      image: {
        width: imageWidth,
        height: imageHeight
      }
    },
    {
      name: 'FRAMED_IMAGE',
      image: {
        width: imageWidth,
        height: imageHeight
      }
    },
    {
      name: 'TWO_IMAGES',
      image: {
        width: imageWidth,
        height: imageHeight / 2
      }
    },
    {
      name: 'TWO_FRAMED_IMAGES',
      image: {
        width: imageWidth - 20,
        height: imageHeight / 2 - 15
      }
    },
    {
      name: 'IMAGE_WITH_TITLE',
      image: {
        width: imageWidth,
        height: imageHeight
      }
    },
    {
      name: 'QUOTE',
      image: {
        width: imageWidth,
        height: imageHeight
      }
    },
    {
      name: 'IMAGE_AND_TEXT',
      image: {
        width: imageWidth,
        height: imageWidth
      }
    },
    {
      name: 'FRAMED_IMAGE_AND_TEXT',
      image: {
        width: imageWidth,
        height: imageWidth
      }
    }
  ];
}

export const DEFAULT_COVER_PAGE_LAYOUT = 'CLASSIC';

export function coverPageLayoutTypes() {
  
  return [
    {
      name: DEFAULT_COVER_PAGE_LAYOUT,
      size: { // In px.
        title: 37,
        subtitle: 16
      }
    },
    {
      name: 'NICO',
      size: {
        title: 51,
        subtitle: 16
      }
    },
    {
      name: 'WANDER',
      size: {
        title: 41,
        subtitle: 18
      }
    },
    {
      name: 'HYDE',
      size: {
        title: 41,
        subtitle: 16
      }
    },
    {
      name: 'SURFACE',
      size: {
        title: 23,
        subtitle: 14
      }
    },
    {
      name: 'LAMODE',
      size: {
        title: 41,
        subtitle: 18
      }
    }
  ];
}

export const DEFAULT_COVER_PAGE_TITLE_SIZE = 32;
export const DEFAULT_COVER_PAGE_SUBTITLE_SIZE = 16;

/// Favorite, Consejos and Block editor settings.

// 17px is defaul font.
export const editorAllowedFontSizes = [
  false, '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '25px', '26px', '27px'
];

export const editorAllowedFonts = [
  false, 'opensans', 'roboto', 'raleway'
];

// Please note the integer data-value-s should be without float point. 
export const editorAllowedLineHeights = [
  false,
  '1',
  '1.2',
  '1.5',
  '1.6',
  '1.8',
  '2',
  '2.4',
  '2.8',
  '3',
  '4',
  '5'
];
export const editorAllowedTextColors = [
  false,
  '#000000',
  '#808080'
];
/// ~ Favorite, Consejos and Block editor settings.

export const FRONTPAGE_STAT = {
  countries: 46,
  continents: 5,
  wondersNature: 4,
  wondersWorld: 6
};

export const FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME = 'frontpage_settings_temporary';
export const FRONTPAGE_SETTINGS_VARIABLE_NAME = 'frontpage_settings';
export const FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE = 60; // PX
export const FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE = 14; // PX
export const FRONTPAGE_SETTINGS_DEFAULT_HERO_TITLE = 'ATREVETE A VIVIR.';
export const FRONTPAGE_SETTINGS_DEFAULT_HERO_SUBTITLE = 'SUEÑOS. AVENTURAS. RECUERDOS.';
export const FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT = 0;
export const FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT = 0;
export const FRONTPAGE_SETTINGS_DEFAULT_DIARIO_TITLE = 'MI DIARIO';
export const FRONTPAGE_SETTINGS_DEFAULT_DIARIO_SUBTITLE = 'INSPIRATION FOR YOUR NEXT ADVENTURE';
export const FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT = 0;
export const FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT = 0;
export const FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT = 50;
export const FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT = 50;
export const FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT = 50;
