import PropTypes from 'prop-types';
import React from "react";
import Select from "react-select";
import { toast } from 'react-toastify';
import { FormGroup, Label } from "reactstrap";
import StoriesApi from '../Core/Api/StoriesApi';

const StoryCategorySelect = ({onChange, defaultValue}) => {

  const [allCategories, setAllCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const mapPropsCategories = (defaultValue, allCategories) => {
    
    let selectedOptions = null;
    if (defaultValue && defaultValue.length > 0) {
      
      selectedOptions = allCategories.filter((item) => {
        return (defaultValue.indexOf(item.value) !== -1);
      });
    }

    return selectedOptions;
  }

  const handleChange = selectedOptions => {
    if (onChange) {
      const values = selectedOptions.map((item) => {
        return item.value;
      });

      onChange.call(null, values);
    }
  }

  React.useEffect(() => {

    setLoading(true);
    StoriesApi.fetchAllStoryCategories()
      .then((data) => {
        if (data.data != null && data.data.publicStoryCategoryList != null) {
          const categories = data.data.publicStoryCategoryList.map((category) => {
            return {
              value: category.id,
              label: category.name
            }
          });

          setAllCategories(categories);
          setLoading(false);
        }
        else {
          // @todo: Handle errors.
          console.warn(data.errors);
          toast.error('An error ocurred. Cannot load categories.')
        }
      })
      .catch((error) => {
        console.warn(error);
        toast.error('An error ocurred. Cannot load categories.')
      });
  }, []);

  return (
    <FormGroup>
      <Label for="category">Categoría</Label>
      {loading && <div style={{color: "var(--primary)"}}>Loading...</div>}
      {!loading && <Select
        value={mapPropsCategories(defaultValue, allCategories)}
        onChange={handleChange} 
        options={allCategories}
        isMulti={true}
        placeholder="Selecciona..."
      />}
    </FormGroup>
  );
}

StoryCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default StoryCategorySelect;
