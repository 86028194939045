import React from 'react';
import { toast } from 'react-toastify';
import YouTube from 'react-youtube';
import FormTopMenu from '../../Components/FormTopMenu';
import { FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT, FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT, FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT, FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT, FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT, FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT, FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT, FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE, FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE, FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME, FRONTPAGE_SETTINGS_VARIABLE_NAME, FRONTPAGE_STAT, SITE_URL } from "../../config";
import VariablesApi from '../../Core/Api/VariablesApi';
import { youtubeVideoId } from '../../Core/Utils';
import Layout from '../../Layout/Layout';
import './FrontpagePreview.scss';

export default class FrontpagePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section_hero_title: '...',
      section_hero_title_size: FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE,
      section_hero_subtitle: '...',
      section_hero_subtitle_size: FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE,
      section_hero_bg_video: ' ',
      section_hero_bg_video_tint: FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT,
      section_hero_bg_image_url: ' ',
      section_hero_bg_image_tint: FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT,
      section_categories_image_1_url: '',
      section_categories_image_1_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT,
      section_categories_image_2_url: '',
      section_categories_image_2_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT,
      section_categories_image_3_url: '',
      section_categories_image_3_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT,
      section_diario_title: '...',
      section_diario_title_size: FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE,
      section_diario_subtitle: '...',
      section_diario_subtitle_size: FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE,
      section_diario_bg_video: ' ',
      section_diario_bg_video_tint: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT,
      section_diario_bg_image_url: ' ',
      section_diario_bg_image_tint: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT,
      section_statistics_countries: FRONTPAGE_STAT.countries,
      section_statistics_continents: FRONTPAGE_STAT.continents,
      section_statistics_wonders_nature: FRONTPAGE_STAT.wondersNature,
      section_statistics_wonders_world: FRONTPAGE_STAT.wondersWorld
    }

    this.youtubeBgOptions = {
      playerVars: {
        controls: 0,
        showinfo: 0,
        rel: 0,
        autohide: 1,
        autoplay: 1,
        loop: 1,
        mute: 1,
        disablekb: 1,
        iv_load_policy: 3,
        playsinline: 1,
        origin: SITE_URL
      }
    }
  }
  
  componentDidMount = () => {
    this.fetchSettings();    
  };

  render = () => {

    const {
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_video_tint,
      section_hero_bg_image_url,
      section_hero_bg_image_tint,
      section_categories_image_1_url,
      section_categories_image_1_tint,
      section_categories_image_2_url,
      section_categories_image_2_tint,
      section_categories_image_3_url,
      section_categories_image_3_tint,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_video_tint,
      section_diario_bg_image_url,
      section_diario_bg_image_tint,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world 
    } = this.state;

    const heroBg = section_hero_bg_image_url ? section_hero_bg_image_url : '/img/index/hero.jpg';
    const heroBgStyle = {backgroundImage: `url(${heroBg})`}

    const sectionHeroBgVideoId = 
      section_hero_bg_video ? youtubeVideoId(section_hero_bg_video) : null;

    const diarioBg = section_diario_bg_image_url ? section_diario_bg_image_url : '/img/index/diario.jpg';
    const diarioBgStyle = {backgroundImage: `url(${diarioBg})`}

    const sectionDiarioBgVideoId = 
      section_diario_bg_video ? youtubeVideoId(section_diario_bg_video) : null;

    const category1Bg = section_categories_image_1_url ? 
      section_categories_image_1_url : '/img/index/section-categories/find.png';
    const category1BgStyle = {backgroundImage: `url(${category1Bg})`}

    const category2Bg = section_categories_image_2_url ? 
      section_categories_image_2_url : '/img/index/section-categories/tips.png';
    const category2BgStyle = {backgroundImage: `url(${category2Bg})`}

    const category3Bg = section_categories_image_3_url ? 
      section_categories_image_3_url : '/img/index/section-categories/experiences.png';
    const category3BgStyle = {backgroundImage: `url(${category3Bg})`}

    return (    
      <Layout bg="light">
        <FormTopMenu items={[
            { action: () => this.props.history.push('/frontpage/'), label: 'BACK' },
            { action: this.handlePublishClick, label: 'PUBLISH' }
        ]} />

        <div className="frontpage-preview d-flex justify-content-center flex-column">
          <section 
            className="section section-hero d-flex flex-column justify-content-center text-center" 
            style={heroBgStyle}
          >
            {!section_hero_bg_video &&
              <div className="tint" style={{opacity: section_hero_bg_image_tint / 100}} />}

            {section_hero_bg_video &&
              <>
                <div className="tint" style={{opacity: section_hero_bg_video_tint / 100}} />
                <YouTube
                  videoId={sectionHeroBgVideoId}
                  opts={this.youtubeBgOptions}
                  containerClassName="video-background"
                  onPlay={(e) => {e.target.a.className = 'loaded'}}
                />
              </>
            }

            <div className="content">
              <div className="cta-subtitle" style={{fontSize: `${section_hero_subtitle_size}px`}}>
                {section_hero_subtitle}
              </div>
              <div className="cta-title" style={{fontSize: `${section_hero_title_size}px`}}>
                {section_hero_title}
              </div>
              <a className="btn btn-light cta-btn" href="/" role="button">SÍGUENOS</a>
            </div>
          </section>

          <div className="double-separator" />

          <section className="section section-categories d-flex justify-content-center flex-column">
            <div className="mx-auto text-center pt-4 pb-4 px-5">
              <h2 className="title">Nada es Imposible <span className="heart-icon" /></h2>
              <p className="text-muted text-box">
              Atrévete a soñar. Todo es empezar. Si te trazas un camino, podrás llegar a tu destino. ¡Vamos!
              </p>
            </div>
            <div className="categories mt-1 mb-3 pt-2 pb-5">
              <div className="mx-auto text-center w-100">
                <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                  <a href="/" className="box box1 mx-2 my-1 my-md-2" style={category1BgStyle}>
                    <div className="caption p-3"
                      style={{backgroundColor: `rgba(0,0,0, ${section_categories_image_1_tint / 100})`}}>
                      <div className="sub">Que Quieres</div>
                      <div className="main">ENCONTRAR?</div>
                    </div>
                  </a>
                  <a href="/" className="box box2 mx-2 my-1 my-md-2" style={category2BgStyle}>
                    <div className="caption p-3"
                      style={{backgroundColor: `rgba(0,0,0, ${section_categories_image_2_tint / 100})`}}>
                      <div className="sub">Consejos</div>
                      <div className="main">PARA VIAJAR</div>
                    </div>
                  </a>
                  <a href="/" className="box box3 mx-2 my-1 my-md-2" style={category3BgStyle}>
                    <div className="caption p-3" 
                      style={{backgroundColor: `rgba(0,0,0, ${section_categories_image_3_tint / 100})`}}>
                      <div className="sub">Aventuras</div>
                      <div className="main">MIS DESTINOS</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> 

          <div className="separator" />

          <section 
            className="section section-diario d-flex flex-column justify-content-center text-center"
            style={diarioBgStyle}
          >
            {!section_diario_bg_video &&
              <div className="tint" style={{opacity: section_diario_bg_image_tint / 100}} />}

            {section_diario_bg_video &&
              <>
                <div className="tint" style={{opacity: section_diario_bg_video_tint / 100}} />
                <YouTube
                  videoId={sectionDiarioBgVideoId}
                  opts={this.youtubeBgOptions}
                  containerClassName="video-background"
                  onPlay={(e) => {e.target.a.className = 'loaded'}}
                />
              </>
            }

            <div className="content">
              <div className="cta-subtitle" style={{fontSize: `${section_diario_subtitle_size}px`}}>
                {section_diario_subtitle}
              </div>
              <div className="cta-title" style={{fontSize: `${section_diario_title_size}px`}}>
                {section_diario_title}
              </div>
              <a className="btn btn-light cta-btn" href="/diario" role="button">EXPLORA</a>
            </div>
          </section>

          <div className="double-separator bottom-none" />

          <section className="section section-statistics d-flex flex-column justify-content-center text-center">
            <div className="normal-container stat d-flex flex-column flex-md-row justify-content-between mx-auto py-3">
                <div className="d-flex flex-row justify-content-start justify-content-md-around m-2">
                  <div className="number mr-3">{section_statistics_countries}</div>
                  <div className="vertical-separator d-flex flex-column justify-content-center">
                    <div className="bg" />
                  </div>
                  <div className="label d-flex flex-column justify-content-center">Países <br/>Visitados</div>
                </div>
                <div className="d-flex flex-row justify-content-start justify-content-md-around m-2">
                  <div className="number mr-3">{section_statistics_continents}</div>
                  <div className="vertical-separator d-flex flex-column justify-content-center">
                    <div className="bg" />
                  </div>
                  <div className="label d-flex flex-column justify-content-center">Continentes <br/>Visitados</div>
                </div>
                <div className="d-flex flex-row justify-content-start justify-content-md-around m-2">
                  <div className="number mr-3">{section_statistics_wonders_nature}</div>
                  <div className="vertical-separator d-flex flex-column justify-content-center">
                    <div className="bg" />
                  </div>
                  <div className="label d-flex flex-column justify-content-center">Maravillas de <br/>la Naturaleza</div>
                </div>
                <div className="d-flex flex-row justify-content-start justify-content-md-around m-2">
                  <div className="number mr-3">{section_statistics_wonders_world}</div>
                  <div className="vertical-separator d-flex flex-column justify-content-center">
                    <div className="bg" />
                  </div>
                  <div className="label d-flex flex-column justify-content-center">Maravillas del <br/>Mundo</div>
                </div>
            </div>
          </section>

          <div className="double-separator top-none" />
        </div>
      </Layout>
    )
  }

  handlePublishClick = (e) => {
    e.preventDefault();

    const {
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_image_url,
      section_categories_image_1_url,
      section_categories_image_2_url,
      section_categories_image_3_url,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_image_url,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world
    } = this.state;

    const value = JSON.stringify({
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_image_url,
      section_categories_image_1_url,
      section_categories_image_2_url,
      section_categories_image_3_url,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_image_url,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world
    });

    this.saveSettings(FRONTPAGE_SETTINGS_VARIABLE_NAME, value, () => {
      toast('Your settings have been published successfully.');
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    const {
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_image_url,
      section_categories_image_1_url,
      section_categories_image_2_url,
      section_categories_image_3_url,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_image_url,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world
    } = this.state;

    const value = JSON.stringify({
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_image_url,
      section_categories_image_1_url,
      section_categories_image_2_url,
      section_categories_image_3_url,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_image_url,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world
    });

    this.saveSettings(FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME, value, () => {
      toast('Your settings have been saved successfully. You may now preview them.');
    });
  }

  fetchSettings = () => {
    VariablesApi.getVariable(FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME)
    .then(data => {
      if (data.errors != null) {
        console.warn(data);
        toast.error("An error occured.");
        return;
      }

      // If settings have never been saved, we continue displaying default values.
      if (data.data.variable === null) {
        return;
      }

      const settings = JSON.parse(data.data.variable.value);

      this.setState({
        ...settings
      })
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      toast.error("An error occured.");
    });
  }

  saveSettings = (name, value, successCallback) => {
    VariablesApi.setVariable(name, value)
    .then(data => {
      if (data.errors != null || data.data.setVariable == null) {
        console.error(data);
        toast.error("An error occured.");
      }

      successCallback.call();
    })
    .catch(err => {
      console.error(err);
      toast.error("An error occured.");
    });
  }
}
