import PropTypes from 'prop-types';
import React from "react";
import { formatDate, formatLocation, stripHtml } from '../Core/Utils';
import { ReactComponent as TrashIcon } from '../Icons/zondicons/trash.svg';
import './ContentItemTeaser.scss';

// Used by both Favorite and Tip content types.
const ContentItemTeaser = ({contentItem, toggleDeleteConfirmation}) => 
  <div className="content-item-teaser">
    <div className="controls d-flex flex-row justify-content-end">
      <button
        onClick={toggleDeleteConfirmation} 
        className="delete-btn">
        <TrashIcon />
      </button>
    </div>

    <div className="content">
        <div className="row-first">
            <div className="photo-container">
                <div className="photo">
                  <img src={contentItem.title_image} alt={contentItem.title} />
                </div>
            </div>
        </div>
        <div className="row-second">
            <div className="summary">
                <div className="info">
                    <span className="location">{formatLocation(contentItem.city, contentItem.country_name)}</span>
                    {contentItem.country_name &&
                      <span className="delimiter">&#9679;</span>
                    }
                    <span className="created-at">{formatDate(contentItem.published_at)}</span>
                </div>
                <div className="title">{stripHtml(contentItem.title)}</div>
                <div className="body">
                    <div>{stripHtml(contentItem.teaser)}</div>
                </div>
            </div>
        </div>
    </div>
  </div>

ContentItemTeaser.propTypes = {
  toggleDeleteConfirmation: PropTypes.func,
  contentItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    title_image: PropTypes.string,
    city: PropTypes.string,
    country_name: PropTypes.string,
    published_at: PropTypes.string,
    teaser: PropTypes.string
  }).isRequired
}

/* only rerenders if props change */
export default React.memo(ContentItemTeaser);
