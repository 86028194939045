import React from 'react';
import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, FormText } from "reactstrap";
import FormTopMenu from '../../Components/FormTopMenu';
import SimpleImageUploadControl from '../../Components/SimpleImageUploadControl';
import { FRONTPAGE_SETTINGS_DEFAULT_DIARIO_SUBTITLE, FRONTPAGE_SETTINGS_DEFAULT_DIARIO_TITLE, FRONTPAGE_SETTINGS_DEFAULT_HERO_SUBTITLE, FRONTPAGE_SETTINGS_DEFAULT_HERO_TITLE, FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE, FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE, FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME, FRONTPAGE_SETTINGS_VARIABLE_NAME, FRONTPAGE_STAT, FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT, FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT, FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT, FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT, FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT, FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT, FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT } from "../../config";
import FrontpageApi from '../../Core/Api/FrontpageApi';
import VariablesApi from '../../Core/Api/VariablesApi';
import Layout from '../../Layout/Layout';

const UPLOAD_TYPE_CATEGORY = 'category';
const UPLOAD_TYPE_BG = 'background';
const UPLOAD_TYPE_BG_HERO = 'hero';
const UPLOAD_TYPE_BG_DIARIO = 'diario';

const UPLOAD_EDITOR_CATEGORY_SIZE = {
  editorWidth: 370 / 2,
  editorHeight: 520 / 2,
}

const UPLOAD_EDITOR_BG_SIZE = {
  width: 1520 / 5,
  height: 700 / 5
}

const DEFAULT_META_TITLE = 'Inicio - Los Viajes de Paula';

export default class FrontpageEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section_hero_title: FRONTPAGE_SETTINGS_DEFAULT_HERO_TITLE,
      section_hero_title_size: FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE,
      section_hero_subtitle: FRONTPAGE_SETTINGS_DEFAULT_HERO_SUBTITLE,
      section_hero_subtitle_size: FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE,
      section_hero_bg_video: '',
      section_hero_bg_video_tint: FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT,
      section_hero_bg_image_url: '',
      section_hero_bg_image_tint: FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT,
      section_categories_image_1_url: '',
      section_categories_image_1_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT,
      section_categories_image_2_url: '',
      section_categories_image_2_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT,
      section_categories_image_3_url: '',
      section_categories_image_3_tint: FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT,
      section_diario_title: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_TITLE,
      section_diario_title_size: FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE,
      section_diario_subtitle: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_SUBTITLE,
      section_diario_subtitle_size: FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE,
      section_diario_bg_video: '',
      section_diario_bg_video_tint: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT,
      section_diario_bg_image_url: '',
      section_diario_bg_image_tint: FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT,
      section_statistics_countries: FRONTPAGE_STAT.countries,
      section_statistics_continents: FRONTPAGE_STAT.continents,
      section_statistics_wonders_nature: FRONTPAGE_STAT.wondersNature,
      section_statistics_wonders_world: FRONTPAGE_STAT.wondersWorld,
      meta_title: DEFAULT_META_TITLE,
      meta_description: '',

      isUploadDialogDisplayed: false,
      uploadType: UPLOAD_TYPE_CATEGORY,
      uploadSlot: 1,
      uploadEditorSize: UPLOAD_EDITOR_CATEGORY_SIZE
    }
  }
  
  componentDidMount = () => {
    this.fetchSettings();    
  };

  render = () => {
    const { 
      section_hero_title,
      section_hero_title_size,
      section_hero_subtitle,
      section_hero_subtitle_size,
      section_hero_bg_video,
      section_hero_bg_video_tint,
      section_hero_bg_image_url,
      section_hero_bg_image_tint,
      section_categories_image_1_url,
      section_categories_image_1_tint,
      section_categories_image_2_url,
      section_categories_image_2_tint,
      section_categories_image_3_url,
      section_categories_image_3_tint,
      section_diario_title,
      section_diario_title_size,
      section_diario_subtitle,
      section_diario_subtitle_size,
      section_diario_bg_video,
      section_diario_bg_video_tint,
      section_diario_bg_image_url,
      section_diario_bg_image_tint,
      section_statistics_countries,
      section_statistics_continents,
      section_statistics_wonders_nature,
      section_statistics_wonders_world,
      meta_title,
      meta_description,

      isUploadDialogDisplayed,
      uploadEditorSize
    } = this.state;

    return (    
      <Layout bg="light">
        <FormTopMenu items={[
            { action: () => this.props.history.push('/'), label: 'BACK' },
            { action: () => this.props.history.push('/frontpage/preview'), label: 'PREVIEW' },
            { action: this.handlePublishClick, label: 'PUBLISH' }
        ]} />

        <main className="normal-container mb-5 mt-5">
          <Form className="p-3">
            <h3>Hero Section</h3>
            <FormGroup>
              <Label for="section_hero_title">Title</Label>
              <Input
                type="text"
                name="section_hero_title"
                id="section_hero_title"
                placeholder="Title"
                value={section_hero_title}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label>Title size ( {`${section_hero_title_size}px`} ){' '} 
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE}px )</span></Label>
              <CustomInput
                type="range"
                id="section_hero_title_size"
                name="section_hero_title_size"
                onChange={this.handleInputChange}
                value={section_hero_title_size}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_hero_subtitle">Subtitle</Label>
              <Input
                type="text"
                name="section_hero_subtitle"
                id="section_hero_subtitle"
                placeholder="Title"
                value={section_hero_subtitle}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label>Subtitle size ( {`${section_hero_subtitle_size}px`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE}px )</span></Label>
              <CustomInput
                type="range"
                id="section_hero_subtitle_size"
                name="section_hero_subtitle_size"
                onChange={this.handleInputChange}
                value={section_hero_subtitle_size}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_hero_bg_image_url">Background Image</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="section_hero_bg_image_url"
                  id="section_hero_bg_image_url"
                  placeholder="https://"
                  value={section_hero_bg_image_url}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.toggleUploadDialog.bind(null, UPLOAD_TYPE_BG, UPLOAD_TYPE_BG_HERO)}>
                    Upload
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Background Image Tint ( {`${section_hero_bg_image_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_IMAGE_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_hero_bg_image_tint"
                name="section_hero_bg_image_tint"
                onChange={this.handleInputChange}
                value={section_hero_bg_image_tint}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_hero_bg_video">Background Video</Label>
              <Input
                type="text"
                name="section_hero_bg_video"
                id="section_hero_bg_video"
                placeholder="https://www.youtube.com/"
                value={section_hero_bg_video}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>

            <FormGroup>
              <Label>Background Video Tint ( {`${section_hero_bg_video_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_HERO_BG_VIDEO_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_hero_bg_video_tint"
                name="section_hero_bg_video_tint"
                onChange={this.handleInputChange}
                value={section_hero_bg_video_tint}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />
           
            <h3>Categories Section</h3>

            <FormGroup>
              <Label for="section_categories_image_1_url">Image 1</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="section_categories_image_1_url"
                  id="section_categories_image_1_url"
                  placeholder="https://"
                  value={section_categories_image_1_url}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.toggleUploadDialog.bind(null, UPLOAD_TYPE_CATEGORY, 1)}>Upload</Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Image 1 Tint ( {`${section_categories_image_1_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_1_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_categories_image_1_tint"
                name="section_categories_image_1_tint"
                onChange={this.handleInputChange}
                value={section_categories_image_1_tint}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_categories_image_2_url">Image 2</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="section_categories_image_2_url"
                  id="section_categories_image_2_url"
                  placeholder="https://"
                  value={section_categories_image_2_url}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.toggleUploadDialog.bind(null, UPLOAD_TYPE_CATEGORY, 2)}>Upload</Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Image 2 Tint ( {`${section_categories_image_2_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_2_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_categories_image_2_tint"
                name="section_categories_image_2_tint"
                onChange={this.handleInputChange}
                value={section_categories_image_2_tint}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_categories_image_3_url">Image 3</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="section_categories_image_3_url"
                  id="section_categories_image_3_url"
                  placeholder="https://"
                  value={section_categories_image_3_url}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.toggleUploadDialog.bind(null, UPLOAD_TYPE_CATEGORY, 3)}>Upload</Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Image 3 Tint ( {`${section_categories_image_3_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_CATEGORY_IMAGE_3_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_categories_image_3_tint"
                name="section_categories_image_3_tint"
                onChange={this.handleInputChange}
                value={section_categories_image_3_tint}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <h3>Mi Diario Section</h3>

            <FormGroup>
              <Label for="section_diario_title">Title</Label>
              <Input
                type="text"
                name="section_diario_title"
                id="section_diario_title"
                placeholder="Title"
                value={section_diario_title}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label>Title size ( {`${section_diario_title_size}px`} ){' '} 
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_TITLE_SIZE}px )</span></Label>
              <CustomInput
                type="range"
                id="section_diario_title_size"
                name="section_diario_title_size"
                onChange={this.handleInputChange}
                value={section_diario_title_size}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_diario_subtitle">Subtitle</Label>
              <Input
                type="text"
                name="section_diario_subtitle"
                id="section_diario_subtitle"
                placeholder="Title"
                value={section_diario_subtitle}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label>Subtitle size ( {`${section_diario_subtitle_size}px`} ){' '} 
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_SUBTITLE_SIZE}px )</span></Label>
              <CustomInput
                type="range"
                id="section_diario_subtitle_size"
                name="section_diario_subtitle_size"
                onChange={this.handleInputChange}
                value={section_diario_subtitle_size}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_diario_bg_image_url">Background Image</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="section_diario_bg_image_url"
                  id="section_diario_bg_image_url"
                  placeholder="https://"
                  value={section_diario_bg_image_url}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.toggleUploadDialog.bind(null, UPLOAD_TYPE_BG, UPLOAD_TYPE_BG_DIARIO)}>
                    Upload
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Background Image Tint ( {`${section_diario_bg_image_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_IMAGE_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_diario_bg_image_tint"
                name="section_diario_bg_image_tint"
                onChange={this.handleInputChange}
                value={section_diario_bg_image_tint}
              />
            </FormGroup>

            <FormGroup>
              <Label for="section_diario_bg_video">Background Video</Label>
              <Input
                type="text"
                name="section_diario_bg_video"
                id="section_diario_bg_video"
                placeholder="https://www.youtube.com/"
                value={section_diario_bg_video}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>

            <FormGroup>
              <Label>Background Video Tint ( {`${section_diario_bg_video_tint}%`} ){' '}
                <span className="text-muted">Default: ( {FRONTPAGE_SETTINGS_DEFAULT_DIARIO_BG_VIDEO_TINT}% )</span></Label>
              <CustomInput
                type="range"
                min={0}
                max={100}
                step={1}
                id="section_diario_bg_video_tint"
                name="section_diario_bg_video_tint"
                onChange={this.handleInputChange}
                value={section_diario_bg_video_tint}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <h3>Statistics Section</h3>
            
            <FormGroup>
              <Label for="section_statistics_countries">Countries</Label>
              <Input
                type="number"
                name="section_statistics_countries"
                id="section_statistics_countries"
                placeholder="Countries"
                value={section_statistics_countries}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label for="section_statistics_continents">Continents</Label>
              <Input
                type="number"
                name="section_statistics_continents"
                id="section_statistics_continents"
                placeholder="Continents"
                value={section_statistics_continents}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label for="section_statistics_wonders_nature">Wonders (Nature)</Label>
              <Input
                type="number"
                name="section_statistics_wonders_nature"
                id="section_statistics_wonders_nature"
                placeholder="Wonders (Nature)"
                value={section_statistics_wonders_nature}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>
            <FormGroup>
              <Label for="section_statistics_wonders_world">Wonders (World)</Label>
              <Input
                type="number"
                name="section_statistics_wonders_world"
                id="section_statistics_wonders_world"
                placeholder="Wonders (World)"
                value={section_statistics_wonders_world}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <h3>Meta Data</h3>

            <FormGroup>
              <Label for="meta_title">Meta Title</Label>
              <Input
                type="text"
                name="meta_title"
                id="meta_title"
                placeholder=""
                value={meta_title}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText>Default: {DEFAULT_META_TITLE}</FormText>
            </FormGroup>

            <FormGroup>
              <Label for="meta_description">Meta Description</Label>
              <Input
                type="textarea"
                name="meta_description"
                id="meta_description"
                placeholder=""
                value={meta_description}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText>Recommended length: 50-160 characters</FormText>
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <Button onClick={this.handleSaveClick}>Save settings</Button>
          </Form>

          <Modal
            isOpen={isUploadDialogDisplayed}
            toggle={this.toggleUploadDialog}
            fade={false}
            centered={true}
          >
            <ModalHeader toggle={this.toggleUploadDialog}>
              Upload image
            </ModalHeader>
            <ModalBody>
              <SimpleImageUploadControl 
                editorWidth={uploadEditorSize.width}
                editorHeight={uploadEditorSize.height}
                onSave={this.handleImageUpload} 
              />
            </ModalBody>
          </Modal>
        </main>
      </Layout>
    )
  }

  toggleUploadDialog = (uploadType, uploadSlot = null) => {
    this.setState(({isUploadDialogDisplayed}) => ({
      isUploadDialogDisplayed: !isUploadDialogDisplayed,
      uploadType,
      uploadSlot,
      uploadEditorSize: uploadType === UPLOAD_TYPE_CATEGORY ? 
        UPLOAD_EDITOR_CATEGORY_SIZE : 
        UPLOAD_EDITOR_BG_SIZE
    }));
  }

  uploadCategoryImage = (originalImageBlob, croppingRect) => {
    FrontpageApi
      .uploadFrontpageCategoryImage(originalImageBlob, croppingRect)
        .then(data => {
          if (data.data.uploadFrontpageCategoryImage) {
            const url = data.data.uploadFrontpageCategoryImage;

            this.setState(({isUploadDialogDisplayed, uploadSlot}) => ({
              [`section_categories_image_${uploadSlot}_url`]: url,
              isUploadDialogDisplayed: !isUploadDialogDisplayed
            }));

            toast('The image have been uploaded. Don\'t forget to save settings to apply changes.');
          } else {
            console.error(data);
            toast.error("An error occured.");
          }
        })
        .catch(err => {
          console.error(err);
          toast.error("An error occured.");
        });
  }

  uploadBgImage = (originalImageBlob, croppingRect) => {
    FrontpageApi
      .uploadFrontpageBgImage(originalImageBlob, croppingRect)
        .then(data => {
          if (data.data.uploadFrontpageBgImage) {
            const url = data.data.uploadFrontpageBgImage;

            this.setState(({isUploadDialogDisplayed, uploadSlot}) => ({
              [`section_${uploadSlot}_bg_image_url`]: url,
              isUploadDialogDisplayed: !isUploadDialogDisplayed
            }));

            toast('The image have been uploaded. Don\'t forget to save settings to apply changes.');
          } else {
            console.error(data);
            toast.error("An error occured.");
          }
        })
        .catch(err => {
          console.error(err);
          toast.error("An error occured.");
        });
  }

  handleImageUpload = (originalImageBlob, croppingRect) => {
    const { uploadType } = this.state;

    switch(uploadType) {
      case UPLOAD_TYPE_CATEGORY:
      default:
        this.uploadCategoryImage(originalImageBlob, croppingRect);
        break;
      case UPLOAD_TYPE_BG:
        this.uploadBgImage(originalImageBlob, croppingRect);
        break;
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleInputKeyDown = event =>  {
    // We have to handle Backspace for inputs additionally.
    if (event.keyCode === 8) {
     this.handleInputChange(event);
    }
  }

  handlePublishClick = (e) => {
    e.preventDefault();

    const settingsJson = JSON.stringify(this.getSettingsFromState(this.state));

    this.saveSettings(FRONTPAGE_SETTINGS_VARIABLE_NAME, settingsJson, () => {
      toast('Your settings have been published successfully.');
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    const settingsJson = JSON.stringify(this.getSettingsFromState(this.state));

    this.saveSettings(FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME, settingsJson, () => {
      this.props.history.push('/frontpage/preview/');
      toast('Your settings have been saved successfully. You may now preview them.');
    });
  }

  getSettingsFromState = (state) => {
    const settings = Object.assign({}, state);

    delete settings.isUploadDialogDisplayed;
    delete settings.uploadType;
    delete settings.uploadSlot;
    delete settings.uploadEditorSize;

    return settings;
  }

  saveSettings = (name, value, successCallback) => {
    VariablesApi.setVariable(name, value)
    .then(data => {
      if (data.errors != null || data.data.setVariable == null) {
        console.error(data);
        toast.error("An error occured.");
      }

      successCallback.call();
    })
    .catch(err => {
      console.error(err);
      toast.error("An error occured.");
    });
  }

  fetchSettings = () => {
    VariablesApi.getVariable(FRONTPAGE_SETTINGS_TEMPORARY_VARIABLE_NAME)
    .then(data => {
      if (data.errors != null) {
        console.warn(data);
        toast.error("An error occured.");
        return;
      }

      // If settings have never been saved, we continue displaying default values.
      if (data.data.variable === null) {
        return;
      }

      const settings = JSON.parse(data.data.variable.value);

      this.setState({
        ...settings
      })
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      toast.error("An error occured.");
    });
  }
}
