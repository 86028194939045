import React from "react";
import Calendar from 'react-calendar';
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label, FormText } from "reactstrap";
import FavoriteCategorySelect from '../../Components/FavoriteCategorySelect';
import FormTopMenu from "../../Components/FormTopMenu";
import ImageUploadControl from "../../Components/ImageUploadControl";
import { DEFAULT_COUNTRY, favoriteStatus } from "../../config";
import CommonApi from "../../Core/Api/CommonApi";
import FavoritesApi from "../../Core/Api/FavoritesApi";
import { dateToStr, strToDate } from '../../Core/Utils';
import Layout from "../../Layout/Layout";

class FavoriteEdit1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      title_image_original: null,
      title_image_settings: null,
      body: '',
      meta_description: '',
      status: favoriteStatus.Draft,
      created_at: '',
      published_at: new Date(),
      categories: [],
      country_code: DEFAULT_COUNTRY,
      city: '',
      allCountries: [],
      displayPublishConfirmation: false,
      error: ''
    };

    this.id = props.match.params.id ? props.match.params.id : '';

    this.q = null;
  }

  componentDidMount = () => {

    this.fetchCountries();

    // If the favorite is new, don't try to load it from the remote source.
    if (!this.id) {
      return;
    }

    this.fetchFavorite(this.id);
  };

  render = () => {

    const {
      title,
      title_image_original,
      title_image_settings,
      categories,
      meta_description,
      published_at,
      country_code,
      allCountries,
      city
    } = this.state;

    const id = this.id;

    return (
      <Layout bg="light">
        <FormTopMenu items={[
            { action: this.handleSaveClick.bind(null, false), label: !id ? "SAVE DRAFT" : "UPDATE" },
            { action: this.handleSaveClick.bind(null, true), label: "CONTINUE >" }
        ]} />

        <main className="normal-container mb-5 mt-2">
          <Form className="p-3">
            <Input id="id" name="id" type="hidden" defaultValue={id} />
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Title"
                defaultValue={title}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
                required={true}
              />
            </FormGroup>
          
            <FavoriteCategorySelect
              defaultValue={categories}
              onChange={this.handleCategoriesChange}
              name="published_at"
            />
            <FormGroup>
              <Label for="published_at">Date</Label>
              <Calendar
                name="published_at"
                onChange={this.handleDateChange}
                value={published_at}
                locale="es"
                className="calendar"
              />
            </FormGroup>
            <FormGroup>
              <Label for="country_code">Country</Label>
              <Input
                type="select"
                name="country_code"
                id="country_code"
                value={country_code}
                onChange={this.handleInputChange}
                placeholder="Select..."
                required={true}
              >
                {allCountries.map(item => (
                  <option value={item.code} key={item.code}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                defaultValue={city}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </FormGroup>

            <FormGroup>
              <Label for="title_image">Title image</Label>
              <ImageUploadControl 
                onSave={this.handleImageUpload}
                originalImagePath={title_image_original}
                originalImageSettings={title_image_settings}
              />
            </FormGroup>

            <hr className="d-block mt-5 pb-4" />

            <FormGroup>
              <Label for="meta_description">Meta Description</Label>
              <Input
                type="textarea"
                name="meta_description"
                id="meta_description"
                placeholder=""
                value={meta_description}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              <FormText>Recommended length: 50-160 characters</FormText>
            </FormGroup>

          </Form>
        </main>
      </Layout>
    );
  };

  handleImageUpload = (originalImageBlob, originalImageSettings, croppingRect) => {
    FavoritesApi
      .uploadFavoriteTitleImage(this.id, originalImageBlob, originalImageSettings, croppingRect)
      .then(data => {
        if (data.data.uploadFavoriteTitleImage && data.data.uploadFavoriteTitleImage.id) {
          const favorite = data.data.uploadFavoriteTitleImage;
          this.setState({
            title_image_original: favorite.title_image_original,
            title_image_settings: favorite.title_image_settings
          });

          toast('The image have been saved successfully');
        } else {
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  handleDateChange = date => {
    this.setState({
      published_at: date
    });
  }

  handleInputChange = event => {
    
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleInputKeyDown = event =>  {
    // We have to handle Backspace for inputs additionally.
    if (event.keyCode === 8) {
     this.handleInputChange(event);
    }
  }

  handleCategoriesChange = (data) => {
    this.setState({
      'categories': data
    });
  };

  handleSaveClick = (redirectToNext = false) => {
    const favorite = Object.assign({}, this.state);
    favorite.published_at = dateToStr(favorite.published_at);

    if (!favorite.id) {
        this.createFavorite(favorite, redirectToNext);
    } else {
        this.updateFavorite(favorite, redirectToNext);
    }
  };

  createFavorite = (favorite, redirectToNext) => {
    FavoritesApi.createFavorite(favorite)
      .then(data => {
        if (data.data.createFavorite) {
          const state = data.data.createFavorite;

          if (state.published_at) {
            state.published_at = strToDate(state.published_at);
          }

          this.setState(state);

          this.props.history.push(redirectToNext ? `/favorite/${state.id}/body/` : '/favorites');
        } else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  updateFavorite = (favorite, redirectToNext) => {
    FavoritesApi.updateFavorite(favorite)
      .then(data => {
        if (data.data.updateFavorite) {
          const state = data.data.updateFavorite;

          if (state.published_at) {
            state.published_at = strToDate(state.published_at);
          }
          
          this.setState(state);

          this.props.history.push(redirectToNext ? `/favorite/${state.id}/body/` : '/favorites');
        } else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  fetchCountries = () => {
    CommonApi.fetchCountries()
    .then(data => {
      if (data.data.countries && data.data.countries.length > 0) {
        this.setState({
          'allCountries': data.data.countries
        });
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  fetchFavorite = (id) => {
    FavoritesApi.fetchFavorite(id)
    .then(data => {
      if (data.data.favorites && data.data.favorites.length > 0) {
        const favorite = data.data.favorites[0];

        favorite.categories = favorite.categories.map(item => {
          return item.id;
        });

        if (favorite.country_code === null) {
          favorite.country_code = '';
        }

        if (favorite.published_at) {
          favorite.published_at = strToDate(favorite.published_at);
        }

        this.setState(favorite);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  reportError = message => {
    toast.error(message)
  };
}

export default FavoriteEdit1;
