import PropTypes from 'prop-types';
import React from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import { FAVORITE_CATEGORIES } from '../config';

// @todo: Refactor in order to use categories from the API. See StoryCategorySelect component.

const FavoriteCategorySelect = (props) => {

  const mapPropsCategories = (props) => {
    
    let selectedOptions = null;
    if (props.defaultValue && props.defaultValue.length > 0) {
      
      selectedOptions = FAVORITE_CATEGORIES.filter((item) => {
        return (props.defaultValue.indexOf(item.value) !== -1);
      });
    }

    return selectedOptions;
  }

  const handleChange = selectedOptions => {

    if (props.onChange) {
      const values = [selectedOptions].map((item) => {
        return item.value;
      });

      props.onChange.call(null, values);
    }
  }

  const selectedOptions = mapPropsCategories(props);

  return (
    <FormGroup>
      <Label for="category">Categoría</Label>
      <Select
        value={selectedOptions}
        onChange={handleChange} 
        options={FAVORITE_CATEGORIES}
        isMulti={false}
        placeholder="Selecciona..."
      />
    </FormGroup>
  );
}

FavoriteCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default FavoriteCategorySelect;
