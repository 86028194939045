import React from 'react';
import ApiBase from '../Core/Api/ApiBase';
import Auth from '../Core/Auth';
import { withRouter } from 'react-router-dom';

class AuthenticationRequired extends React.Component {

  componentDidMount = () => {

    if (!Auth.isLoggedIn()) {
      return this.handleInvalidToken();
    }

    this.validateToken();
  };

  render = () => ''

  validateToken = () => {
    if (this.props.location.pathname === '/login/') {
      return;
    }

    ApiBase.validateToken()
    .then(data => {
      if (data.errors && data.data.validateToken !== true) {
        this.handleInvalidToken();
      }
    })
    .catch(err => {
      this.handleInvalidToken(err);
    });
  }

  handleInvalidToken(consoleMessage = null) {
    if (consoleMessage != null) {
      console.error(consoleMessage);
    }
    if (this.props.location.pathname !== '/login/') {
      Auth.removeAuthToken();
      this.props.history.push('/login/');
    }
  }
}

export default withRouter(AuthenticationRequired);
