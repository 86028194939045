import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormTopMenu from "../../Components/FormTopMenu";
import { tipStatus, editorAllowedFontSizes, editorAllowedFonts, editorAllowedLineHeights, editorAllowedTextColors } from "../../config";
import CommonApi from "../../Core/Api/CommonApi";
import TipsApi from "../../Core/Api/TipsApi";
// We should register Quill pluging using their own way.
import '../../Core/Quill/blots/ResponsiveVideo';
import { dateToStr, strToDate } from '../../Core/Utils';
import Layout from "../../Layout/Layout";
import "./TipEdit2.scss";

//~ Quill extension.

const icons = Quill.import("ui/icons");
icons["header"] = "H";
// @todo: Better import svg from 'quill/assets/icons/video.svg'.
icons["responsive-video"] = '<svg viewBox="0 0 18 18"><rect class="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="5" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="12" y="3"></rect> <rect class="ql-fill" height="2" width="8" x="5" y="8"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="12"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="12"></rect> </svg>';

const Delta = Quill.import("delta");
const matcher = (node, delta) => {
  return delta.compose(new Delta().retain(delta.length(), { header: 3 }));
};

// 17px is defaul font.
const allowedFontSizes = editorAllowedFontSizes;

const sizeStyle = Quill.import('attributors/style/size');
sizeStyle.whitelist = allowedFontSizes;
Quill.register(sizeStyle, true);

const allowedFonts = editorAllowedFonts;

const fontClass = Quill.import('attributors/class/font');
fontClass.whitelist = allowedFonts;
Quill.register(fontClass, true);

// Please note the integer data-value-s should be without float point. 
const allowedLineHeights = editorAllowedLineHeights;

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: allowedLineHeights
};
// const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
// Quill.register(lineHeightClass);
Quill.register(lineHeightStyle);

//~ Quill extension.

class TipEdit2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      body: '',
      status: tipStatus.Draft,
      published_at: new Date(),
      displayPublishConfirmation: false,
      error: ''
    };

    this.id = props.match.params.id ? props.match.params.id : '';

    this.textBox = null;

    this.quill = null;
  }

  setRef = node => {
    if (node == null) {
      return;
    }

    this.quill = node;
  }
  
  componentDidMount = () => {

    this.fetchCountries();

    // If the tip is new, don't try to load it from the remote source.
    if (!this.id) {
      return;
    }

    this.fetchTip(this.id);    
  };

  render = () => {
    const { body, displayPublishConfirmation } = this.state;

    return (
      <Layout bg="light">
        <FormTopMenu items={[
            { action: () => this.props.history.push('/tips'), label: 'BACK' },
            { action: this.togglePublishConfirmation, label: "PUBLISH" }
        ]} />

        <main className="normal-container mb-5 mt-2">
          <ReactQuill
            theme="snow"
            modules={this.modules}
            formats={this.formats}
            placeholder="..."
            value={body}
            onChange={this.handleEditorChange}
            ref={this.setRef}
            className="tip-editor"
          />
        </main>

        <Modal
          isOpen={displayPublishConfirmation}
          toggle={this.togglePublishConfirmation}
          fade={false}
          centered={true}
        >
          <ModalHeader toggle={this.togglePublishConfirmation}>
            Publish Confirmation
          </ModalHeader>
          <ModalBody>Do you really want to publish the tip?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.publishTip}>
              Publish
            </Button>
            &nbsp;
            <Button color="secondary" onClick={this.togglePublishConfirmation}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Layout>
    );
  };

  handleEditorChange = value => {
    this.setState({
      body: value
    });
  }

  handleSaveClick = (redirectToNext = false) => {
    const tip = Object.assign({}, this.state);
    tip.published_at = dateToStr(tip.published_at);

    this.updateTipBody(tip, redirectToNext);
  };

  togglePublishConfirmation = () => {
    this.setState({
      displayPublishConfirmation: !this.state.displayPublishConfirmation
    });
  };

  publishTip = () => {
    const {id, body} = this.state;

    TipsApi.publishTip(id, body)
      .then(data => {
        if (data.data.publishTip) {
          const story = data.data.publishTip;

          this.setState({
            status: story.status
          });

          this.props.history.push('/tips');
        } else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  updateTipBody = (tip, redirectToNext) => {
    TipsApi.updateTipBody(tip)
        .then(data => {
          if (data.data.updateTipBody) {
            const state = data.data.updateTipBody;
            
            this.setState(state);

            this.props.history.push(redirectToNext ? `/tip/${state.id}/body/` : '/tips');
          } else {
            // @todo: Handle errors better.
            console.warn(data);
            this.reportError("An error occured.");
          }
        })
        .catch(err => {
          // @todo: Handle errors better.
          console.warn(err);
          this.reportError("An error occured.");
        });
  }

  fetchCountries = () => {
    CommonApi.fetchCountries()
      .then(data => {
        if (data.data.countries && data.data.countries.length > 0) {
          this.setState({
            'allCountries': data.data.countries
          });
        } else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  fetchTip = (id) => {
    TipsApi.fetchTip(id)
    .then(data => {
      if (data.data.tips && data.data.tips.length > 0) {
        const tip = data.data.tips[0];

        if (tip.country_code === null) {
          tip.country_code = '';
        }

        if (tip.published_at) {
          tip.published_at = strToDate(tip.published_at);
        }

        this.setState(tip);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  reportError = message => {
    toast.error(message)
  };

  modules = {
    toolbar: {
      container: [
        { 'color': editorAllowedTextColors },
        { 'font': allowedFonts },
        { 'size': allowedFontSizes },
        { 'lineheight': allowedLineHeights },
        { header: 3 },
        'bold',
        'italic',
        { list: 'ordered' },
        { list: 'bullet' },
        'link',
        'blockquote',
        'image',
        'responsive-video'
      ],
      handlers: {
        "responsive-video": function(value) {
          if (value) {
            let url = prompt("Please enter video url");
            if (url != null) {
              const range = this.quill.getSelection(true);
              // this.quill.insertText(range.index, "\n", Quill.sources.USER);
              this.quill.insertEmbed(range.index, "responsive-video", url, Quill.sources.USER);
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
          }
        },
        'image': this.imageHandler.bind(null, this)
      }
    },
    clipboard: {
      matchVisual: false, // https://quilljs.com/docs/modules/clipboard/#matchvisual
      matchers: [["h1", matcher], ["h2", matcher]]
    }
  };

  formats = [
    'color',
    'font',
    'size',
    'lineheight',
    'header',
    'bold',
    'italic',
    'link',
    'blockquote',
    'list',
    'image',
    'responsive-video'
  ];

  imageHandler(component) {

    const quill = component.quill;
    const quillEditor = quill.getEditor();
    const tipId = component.id;

    let fileInput = quillEditor.container.querySelector('input.ql-image[type=file]');
    if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*');
        fileInput.classList.add('ql-image');
        fileInput.addEventListener('change', () => {
            const files = fileInput.files;
            const range =  quillEditor.getSelection(true);
            
            if (!files || !files.length) {
                toast.warn('No files selected');
                return;
            }

            quillEditor.enable(false);

            TipsApi
              .uploadTipContentImage(tipId, files[0])
              .then(data => {
                if (data.data.uploadTipContentImage && data.data.uploadTipContentImage.id) {
                  const tipImage = data.data.uploadTipContentImage;

                  quillEditor.enable(true);
                  quillEditor.insertEmbed(range.index, 'image', tipImage.url);
                  quillEditor.insertText(range.index + 1, ' ', Quill.sources.SILENT);
                  quillEditor.setSelection(range.index + 2, Quill.sources.SILENT);
                  fileInput.value = '';
                } else {
                  console.warn(data);
                  component.reportError("An error occured.");
                  quillEditor.enable(true);
                }
              })
              .catch(err => {
                console.warn(err);
                component.reportError("An error occured.");
                quillEditor.enable(true);
              });
        });
        quillEditor.container.appendChild(fileInput);
    }
    fileInput.click();
  }
}

export default TipEdit2;
