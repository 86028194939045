import React from "react";
import "react-quill/dist/quill.bubble.css";
import Sortable from 'react-sortablejs';
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FormTopMenu from "../Components/FormTopMenu";
import PageList from "../Components/PageList";
import { COVER_PAGE_LAYOUT, layoutTypes } from "../config";
import PagesApi from "../Core/Api/PagesApi";
import StoriesApi from "../Core/Api/StoriesApi";
import { ReactComponent as CloseIcon } from '../Icons/zondicons/close.svg';
import Layout from "../Layout/Layout";
import "./StoryEdit2.scss";

class StoryEdit2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id ? props.match.params.id : '',
      activePageId: props.match.params.pageId ? props.match.params.pageId : null,
      pages: [],
      openLayoutPanel: false,
      status: 0,
      displayPublishConfirmation: false,
      displayDeleteConfirmation: false,
      sortMode: false
    };

    this.layouts = layoutTypes()
      .filter(layout => layout.name !== COVER_PAGE_LAYOUT);
  }

  componentDidMount = () => {

    // If the story if new, don't try to load it from the remote source.
    if (!this.state.id) {
      return;
    }

    this.fetchPages(this.state.id);
  };

  render = () => {
    const {id, sortMode, pages, openLayoutPanel, displayPublishConfirmation} = this.state;
    return (
      <Layout bg="dark">
        <FormTopMenu items={[
            { action: () => this.props.history.push(`/story/${id}/`), label: 'BACK' },
            { action: () => this.props.history.push(`/story/${id}/preview/`), label: 'PREVIEW' },
            { action: this.togglePublishConfirmation, label: "PUBLISH" }
        ]} />
        
        <main className="my-3 mx-auto">
          
          {!sortMode &&
            <div className="my-3 pb-5 d-flex flex-column flex-sm-row flex-sm-wrap justify-content-sm-center pages">
              <PageList 
                storyId={id} 
                pages={pages} 
                onDeleteCallback={this.handleDeletePage}
              />
            </div>
          }

          {sortMode &&
            <Sortable
              options={{
                filter: ".sortable-ignore"
              }}
              onChange={(order, sortable, evt) => {
                  this.handleChangeOrder(order);
              }}
              className="my-3 pb-5 d-flex flex-column flex-sm-row flex-sm-wrap justify-content-sm-center pages"
            >
              <PageList storyId={id} pages={pages} mode="sort" />
            </Sortable> 
          }
        </main>

        <footer className="bottom-bar buttonless d-flex flex-row justify-content-between">
          <button onClick={this.toggleLayoutPanel}>
            <span>Add</span>
          </button>
          <button 
            onClick={this.toggleSortMode} 
            className={sortMode ? 'text-primary' : ''}
          >
            Reorder
          </button>
        </footer>

        <div className={'layout-panel ' + (openLayoutPanel ? 'show' : 'hide')}>
          <div className="close-button" onClick={this.toggleLayoutPanel}>
            <CloseIcon />
          </div>
          <div className="layouts d-flex flex-row justify-content-start justify-content-md-center py-4 px-2 mt-3">
            {this.layouts.map(layout => 
                <button key={layout.name} className="layout mx-2 mx-sm-2" onClick={this.handleLayoutClick.bind(null, layout.name)}>
                  <img src={`/img/PageLayouts/${layout.name}.svg`} alt="" />
                </button>
            )}
          </div>
        </div>

        <Modal
          isOpen={displayPublishConfirmation}
          toggle={this.togglePublishConfirmation}
          fade={false}
          centered={true}
        >
          <ModalHeader toggle={this.togglePublishConfirmation}>
            Publish Confirmation
          </ModalHeader>
          <ModalBody>Do you really want to publish the story?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.publishStory}>
              Publish
            </Button>
            &nbsp;
            <Button color="secondary" onClick={this.togglePublishConfirmation}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Layout>
    );
  };

  setEditorRef = (editor) => this.editor = editor

  toggleLayoutPanel = () => {
    this.setState({openLayoutPanel: !this.state.openLayoutPanel});
  }

  toggleSortMode = () => {
    this.setState({sortMode: !this.state.sortMode});
  }

  handleLayoutClick = (layout) => {

    let order = 1;
    if (this.state.pages.length > 0) {
      order = this.state.pages[this.state.pages.length - 1].order + 1;
    }

    const page = {
      'story_id': this.state.id,
      'layout': layout,
      'order': order,
    }

    this.createPage(page);
  }

  togglePublishConfirmation = () => {
    this.setState({
      displayPublishConfirmation: !this.state.displayPublishConfirmation
    });
  };

  handleDeletePage = (pageId) => {
    let pages = this.state.pages;

    pages = pages.filter((page) => page.id !== pageId);

    this.setState({pages});
  }

  handleChangeOrder = (order) => {
    PagesApi.orderPages(this.state.id, order)
    .then(data => {
      if (data.errors) {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
      else if (data.data.orderPages && data.data.orderPages.length > 0) {
        this.setState({pages: data.data.orderPages});
      } 
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  createPage = (page) => {
    PagesApi.createPage(page)
    .then(data => {
      if (data.data.createPage) {
        const page = data.data.createPage;
        const pages = this.state.pages.slice(0);
        pages.push(page);

        this.setState({
          pages,
          openLayoutPanel: false // Hide panel after adding a page.
        });
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  fetchPages = (storyId) => {
    PagesApi.fetchPages(storyId)
    .then(data => {
      if (data.errors) {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
      else if (data.data.pages && data.data.pages.length > 0) {
        this.setState({pages: data.data.pages});
      } 
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  publishStory = () => {
    StoriesApi.publishStory(this.state.id)
      .then(data => {
        if (data.data.publishStory) {
          const story = data.data.publishStory;

          this.setState({
            status: story.status
          });

          this.props.history.push('/stories');
        } else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError("An error occured.");
      });
  };

  reportError = message => {
    toast.error(message)
  };
}

export default StoryEdit2;
