import ApiBase from "./ApiBase";

export default class CommonApi extends ApiBase {
  static fetchCountries = () => {
    const data = {
      query: `
            query{
              countries{
                code,
                name
              }
            }
        `
    };

    return this.request(data);
  };
}
