import { API_URL } from "../../config";
import Auth from "../Auth";
import HttpClient from "../HttpClient";

export default class ApiBase {
  static logIn = (email, password) => {
    const data = {
      query: `
        mutation logIn($email: String!, $password: String!) {
          logIn(email: $email, password: $password)
        }
        `,
      variables: {
        email,
        password
      }
    };

    return this.request(data);
  };

  static validateToken = () => {
    const data = {
      query: `
        query validateToken {
          validateToken
        }
        `,
      variables: {}
    };

    return this.authRequest(data);
  };

  static authUploadRequest = (operations) => {
    const token = Auth.getAuthToken();
    if(token === null) {
      return Promise.reject('Authentication required');
    }

    return new HttpClient(API_URL)
      .upload(operations, {}, token);
  }

  static authRequest = (operations) => {
    const token = Auth.getAuthToken();
    if(token === null) {
      return Promise.reject('Authentication required');
    }

    return new HttpClient(API_URL)
      .post(operations, {}, token);
  }

  static request = (operations) => {
    return new HttpClient(API_URL)
      .post(operations);
  }
}
