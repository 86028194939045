import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ContentItemTeaser from '../../Components/ContentItemTeaser';
import { tipStatus } from '../../config';
import TipsApi from '../../Core/Api/TipsApi';
import Auth from '../../Core/Auth';
import { groupItemsByRows } from '../../Core/Utils';
import { ReactComponent as AddIcon } from '../../Icons/zondicons/add-outline.svg';
import Layout from '../../Layout/Layout';
import './Tips.scss';

class Tips extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tips: [],
      deletingTipId: null,
      displayDeleteConfirmation: false,
      error: '',
      activeStatus: 0
    }
  }

  componentDidMount = () => {
    this.fetchTips(this.state.activeStatus);
  }

  render = () => {

    const {activeStatus, tips, displayDeleteConfirmation} = this.state;

    const tipsGroupedByRow = groupItemsByRows(tips);

    return (
      <Layout>      
        <header>
          <div className="d-flex flex-row px-0 py-1 text-center top-menu">
            <Link to="/" className="w-50 py-2">
              HOME
            </Link>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === tipStatus.Draft ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, tipStatus.Draft)}>
              DRAFTS
            </button>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === tipStatus.Published ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, tipStatus.Published)}>
              PUBLISHED
            </button>
          </div>
        </header>

        <main className="w-100 my-5 mx-auto">
          {tips.length === 0 && 
              <div className="text-center">No tips to display.</div>
          } 
          <div className="container-fluid tips">
            {tipsGroupedByRow.map((row, index) => (
              <div key={index} className="row justify-content-center">
                {row.map(tip => (
                  <div key={tip.id} className="col-sm col-sm-4">
                    <Link
                      className="tip" 
                      to={'/tip/' + tip.id}
                      onClick={this.allowDeleteButtonClick}
                    >
                      <ContentItemTeaser
                        contentItem={tip}
                        toggleDeleteConfirmation={this.toggleDeleteConfirmation.bind(null, tip.id)} 
                      />
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <Modal isOpen={displayDeleteConfirmation} toggle={this.toggleDeleteConfirmation} fade={false} centered={true}>
            <ModalHeader toggle={this.toggleDeleteConfirmation}>Delete Confirmation</ModalHeader>
            <ModalBody>
              Do you really want to delete the tip?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleDeleteClick}>Delete</Button>&nbsp;
              <Button color="secondary" onClick={this.toggleDeleteConfirmation}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <footer className="bottom-bar buttonless">
            <Link className="add-button" to="/tip/">
              <AddIcon />
            </Link>
          </footer>
        </main>
      </Layout>
    );
  }

  allowDeleteButtonClick = e => {
    
    if(e.target.classList.contains('delete-btn')) {
      e.preventDefault();
    }
  }

  fetchTips = (status) => {
    TipsApi.fetchTips(status)
      .then(data => {
        if (data.data.tips) {
          this.setState({
            tips: data.data.tips
          });
        }
        else {
          // @todo Report user error.
          if (data.errors) {
            data.errors.forEach(item => {
              if (item.message === 'Unauthorized') {
                Auth.removeAuthToken();
                this.props.history.push('/login/');
                return;
              }
            });
          }
          
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        console.warn(err);

        // @todo: Handle errors better.
        this.reportError('An error occured.');
      });
  }

  setActiveStatus = (status) => {
    if (status === this.state.activeStatus) {
      return;
    }

    this.setState({
      'activeStatus': status
    });

    this.fetchTips(status);
  }

  toggleDeleteConfirmation = (tipId) => {
    this.setState({
      displayDeleteConfirmation: !this.state.displayDeleteConfirmation,
      deletingTipId: tipId
    });
  }

  handleDeleteClick = () => {

    // @todo Check for errors.

    const tipId = this.state.deletingTipId;
    
    TipsApi.deleteTip(tipId)
      .then((data) => {
        if (data.data.deleteTip.id) {
          
          let updatedTips = this.state.tips;
        
          updatedTips = updatedTips.filter((item) => {
            return tipId !== item.id;
          });

          this.setState({
            tips: updatedTips
          });
        }
        else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError('An error occured.');
      });

      this.toggleDeleteConfirmation();
  }

  reportError = (message) => {
    toast.error(message)
  }
}

export default Tips;
