import loadImage from 'blueimp-load-image';
import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import Hammer from 'react-hammerjs';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Spinner } from 'reactstrap';
import { layoutTypes } from '../config';
import PagesApi from '../Core/Api/PagesApi';
import Auth from '../Core/Auth';
import { ReactComponent as AddIcon } from '../Icons/zondicons/add-outline.svg';
import { ReactComponent as PhotoIcon } from '../Icons/zondicons/photo.svg';
import { ReactComponent as RefreshIcon } from '../Icons/zondicons/refresh.svg';
import Layout from '../Layout/Layout';
import styles from './EditPageImage.module.scss';

const DEFAULT_SCALE = 1;

class EditPageImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: Auth.isLoggedIn(),
      uploadedOriginalFile: null,
      width: null,
      height: null,
      image: null,
      scale: DEFAULT_SCALE,
      displayProgressSelect: false,
      displayProgressSave: false
    }

    this.imageMime = 'image/png';
    this.imageQuality = 0.95;
    this.fileUploadInput = null;

    const {id, pageId, order} = this.props.match.params;

    this.id = id ? parseInt(id, 10) : null;
    this.pageId = pageId ? parseInt(pageId, 10) : null;
    this.order = parseInt(order, 10);

    // @todo: Raise an error if pageId is not set!
  }

  componentDidMount = () => {

    // Just in case, check if all parameters passed through the url.
    if (!this.id || !this.pageId) {
      return;
    }

    this.fetchPage(this.pageId, this.order);
  };

  render = () => {
    const {image, displayProgressSave, displayProgressSelect, width, height, scale} = this.state;

    return (
      <Layout>
        <header>
          <div className="d-flex flex-row justify-content-between px-0 py-1 text-center top-menu">
            <Link to={`/story/${this.id}/pages/${this.pageId}`}>BACK</Link>
            {image &&
              <button className="text-primary" onClick={this.saveImageClick} disabled={!image}>
                SAVE {displayProgressSave && <Spinner size="sm" color="primary" className="ml-2" /> }
              </button>}
          </div>
        </header>

        {!width && 
          <p className="m-3 text-center text-muted">Loading...</p>}

        {width &&
            <div className="compact-container d-flex flex-column justify-content-center mx-auto text-center full-height">

              <div className={styles['buttons']}>
                <button 
                  className={styles['add-button']}
                  onClick={this.selectLocalImage}
                >
                  <AddIcon />
                </button>

                <button 
                  className={styles['add-button']}
                  onClick={this.resetToOriginal}
                >
                  <RefreshIcon />
                </button>
              </div>
             
              <Hammer onDoubleTap={this.selectLocalImage}>
                <div className={styles['image-container']}>
                  <AvatarEditor
                    image={image}
                    crossOrigin="anonymous"
                    width={width}
                    height={height}
                    border={15}
                    color={[150, 150, 150, 0.6]} // RGBA
                    scale={scale}
                    rotate={0}
                    ref={this.setEditorRef}
                    className="image-editor"
                    onLoadFailure={this.onLoadFailure}
                  />
                  {!image && !displayProgressSelect &&
                    <div className={styles['edit-button']}>
                      <PhotoIcon />
                    </div>
                  }
                  {displayProgressSelect && 
                    <Spinner size="lg" color="secondary" className={styles['my-spinner']} /> }
                </div>
              </Hammer>

              <input 
                type="file" 
                accept="image/*"
                style={{display: "none"}} 
                ref={input => this.fileUploadInput = input}
                onChange={this.onFileChange}
              />
      
              <input style={{width: '260px'}}
                name="scale"
                type="range"
                onChange={this.handleScale}
                min="0.5"
                max="2"
                step="0.01"
                value={scale}
                className="mx-auto mt-3"
              />
            </div>
          }
      </Layout>
    );
  }

  setEditorRef = (editor) => this.editor = editor

  saveImageClick = () => {
    const {image, uploadedOriginalFile, image_original} = this.state;

    if (image === null) {
      this.reportError("Please select an image first.");
      return false;
    }

    this.setState({displayProgressSave: true});

    // Everthing we get from AvatarEditor is the cropping rectangle 
    // because we do image processing at the backend level.
    const croppingRect = this.editor.getCroppingRect();
    // const canvas = this.editor.getImage();
    // const canvas = this.editor.getImageScaledToCanvas();

    // this.setState({processedImage: canvas.toDataURL()});

    // If AvatarEditor loaded an image by url which we received from the backend and user didn't use file uploading,
    // we need to load the original image and send it to the backend.
    if (uploadedOriginalFile === null) {
      fetch(image_original)
        .then(res => res.blob())
        .then(blob =>{
          this.uploadImage(blob, croppingRect);
        });

      // canvas.toBlob(
      //   (blob) => {
      //     this.uploadImage(blob, croppingRect);
      //   }, 
      //   this.imageMime, 
      //   this.imageQuality
      // );
      return;
    }

    // If user just uploaded an image, we're sending it to the backed.
    this.uploadImage(uploadedOriginalFile, croppingRect);
  }

  onLoadFailure = (e) => {
    console.error(e);
    toast.error('Could not load the image.');
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  onFileChange = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    this.setState({
      displayProgressSelect: true, 
      uploadedOriginalFile: file
    });

    // file type is only image.
    if (/^image\//.test(file.type)) {

      // Fix orientation if necessary.

      const loadingImage = loadImage(
          file,
          (canvas) => {
            if(canvas.type === "error") {
              toast.error("An error occurred while loading the image: " + canvas.message);
            } else {
              // Pass the resulted image to the AvatarEditor for further manipulations.
              this.setState({
                image: canvas.toDataURL(),
                scale: DEFAULT_SCALE
              });

              this.setState({displayProgressSelect: false});
              // toast.success('Your image has been loaded. Now you may adapt it.');
            }
          },
          {
              orientation: true,
              // Return result as convas.
              canvas: true
          }
      );

      if (!loadingImage) {
        toast.error('Image processing error');
        return false;
      }

    } else {
      toast.warn('You can only upload images.');
    }
  }

  resetToOriginal = () => {
    const newState = this.state;

    newState.image = this.state.image_original;
    newState.scale = DEFAULT_SCALE;

    this.setState({ state: newState });
  }

  selectLocalImage = () => {

    if (!this.fileUploadInput) {
      return;
    }

    // Trigger click on the hidden file input.
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', false, true, window);  
    this.fileUploadInput.dispatchEvent(event);
  }

  getImageSize = (pageLayout) => {
    const layoutType = layoutTypes().find((layout) => {
      return (layout.name === pageLayout);
    })

    return layoutType.image;
  }

  fetchPage = (pageId, order) => {
    PagesApi.fetchPage(pageId)
    .then(data => {
      if (data.errors) {
        console.warn(data);
        this.reportError("An error occured.");
      }
      else if (data.data.pages && data.data.pages.length > 0) {
        const page = data.data.pages[0];

        const size = this.getImageSize(page.layout);

        const image = page.images.find((image) => {
            return image.order === order;
        });

        this.setState({
          ...size,
          image: image ? image.url : null,
          image_original: image ? image.url_original : null
        });
      } 
    })
    .catch(err => {
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  /**
   * Upload the image file to the server and send it's croping rectangle.
   *
   * @param {File} file
   * @param croppingRect
   */
  uploadImage = (file, croppingRect) => {
    PagesApi.uploadPageImage(this.pageId, this.order, croppingRect, file)
      .then(data => {
        if (data.data.uploadPageImage && data.data.uploadPageImage.id) {
          this.setState({
            displayProgressSave: false
          });
          this.props.history.push(`/story/${this.id}/pages/${this.pageId}/`);
        } else {
          console.warn(data);
          this.reportError("An error occured.");
        }
      })
      .catch(err => {
        console.warn(err);
        this.reportError("An error occured.");
      });
  }

  reportError = (message) => {
    console.error(message);
    toast.error(message)
  }
}

export default EditPageImage;
