import { Quill } from "react-quill";
import { default as QuillLink } from 'quill/formats/link';

// const Block = Quill.import("blots/block");
const BlockEmbed = Quill.import("blots/block/embed");

// @todo: import ResponsiveVideo from '../Classes/Quill/blots/responsive-video'. It doesn't work for some reason.

class ResponsiveVideo extends BlockEmbed {
  static blotName = "responsive-video";
  static tagName = "div";
  static className = "responsive-video";

  static create(value) {
    const node = super.create();

    node.classList.add("embed-responsive");
    node.classList.add("embed-responsive-16by9");

    const child = document.createElement("iframe");
    child.setAttribute("src", this.transform(this.sanitize(value)));
    child.setAttribute("frameborder", 0);
    child.setAttribute("allowfullscreen", true);
    child.classList.add("embed-responsive-item");

    node.appendChild(child);

    return node;
  }

  // get value of the node (for implement undo function)
  static value(node) {
    return node.firstChild.getAttribute("src");
  }

  static sanitize(url) {
    return QuillLink.sanitize(url);
  }

  // Borrowed from Quill. See extractVideoUrl(url).
  static transform(url) {
    let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
                url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
    if (match) {
      return (match[1] || 'https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
    }
    if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) {  // eslint-disable-line no-cond-assign
      return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
    }
    return url;
  }
}

// Register Quill module using their own way.
Quill.register(ResponsiveVideo);
