import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CoverPageContent from '../Components/CoverPageContent';
import { storyStatus } from '../config';
import StoriesApi from '../Core/Api/StoriesApi';
import Auth from '../Core/Auth';
import { ReactComponent as AddIcon } from '../Icons/zondicons/add-outline.svg';
import { ReactComponent as TrashIcon } from '../Icons/zondicons/trash.svg';
import Layout from '../Layout/Layout';
import './Stories.scss';

class Stories extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      stories: [],
      deletingStoryId: null,
      displayDeleteConfirmation: false,
      error: '',
      activeStatus: 0
    }
  }

  componentDidMount = () => {
    this.fetchStories(this.state.activeStatus);
  }

  render = () => {

    const {activeStatus, stories, displayDeleteConfirmation} = this.state;

    return (
      <Layout>      
        <header>
          <div className="d-flex flex-row px-0 py-1 text-center top-menu">
            <Link to="/" className="w-50 py-2">
              HOME
            </Link>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === storyStatus.Draft ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, storyStatus.Draft)}>
              DRAFTS
            </button>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === storyStatus.Published ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, storyStatus.Published)}>
              PUBLISHED
            </button>
          </div>
        </header>

        <main className="my-3 mx-auto">
          <div className="my-3 pb-5 d-flex flex-column flex-sm-row flex-sm-wrap justify-content-sm-center stories">
            {stories.length === 0 && 
              <div className="text-center">No stories to display.</div>
            } 
            {stories.map((story) => (
              <Link
                key={story.id} 
                className={'mb-2 story' + (story.status === 0 ? ' draft' : '') + ' COVER has-bg ' + story.cover_layout} 
                style={{
                  backgroundImage: story.titlePages.length > 0 && story.titlePages[0].images.length ? 
                    `url("${story.titlePages[0].images[0].url}")` : 'none'
                }}
                to={'/story/' + story.id}
                onClick={this.allowDeleteButtonClick}
              >
                <div className="controls d-flex flex-row justify-content-end">
                  <button onClick={this.toggleDeleteConfirmation.bind(null, story.id)} className="delete-btn">
                     <TrashIcon />
                  </button>
                </div>

                <CoverPageContent {...story} />
              </Link>
            ))}
          </div>

          <Modal isOpen={displayDeleteConfirmation} toggle={this.toggleDeleteConfirmation} fade={false} centered={true}>
            <ModalHeader toggle={this.toggleDeleteConfirmation}>Delete Confirmation</ModalHeader>
            <ModalBody>
              Do you really want to delete the story?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleDeleteClick}>Delete</Button>&nbsp;
              <Button color="secondary" onClick={this.toggleDeleteConfirmation}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <footer className="bottom-bar buttonless">
            <Link className="add-button" to="/story/">
              <AddIcon />
            </Link>
          </footer>
        </main>
      </Layout>
    );
  }

  allowDeleteButtonClick = e => {
    
    if(e.target.classList.contains('delete-btn')) {
      e.preventDefault();
    }
  }

  fetchStories = (status) => {
    StoriesApi.fetchStories(status)
      .then((data) => {
        if (data.data.stories) {
          this.setState({
            stories: data.data.stories
          });
        }
        else {
          // @todo Report user error.
          if (data.errors) {
            data.errors.forEach(item => {
              if (item.message === 'Unauthorized') {
                Auth.removeAuthToken();
                this.props.history.push('/login/');
                return;
              }
            });
          }
          
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        console.warn(err);

        // @todo: Handle errors better.
        this.reportError('An error occured.');
      });
  }

  setActiveStatus = (status) => {
    if (status === this.state.activeStatus) {
      return;
    }

    this.setState({
      'activeStatus': status
    });

    this.fetchStories(status);
  }

  toggleDeleteConfirmation = (storyId) => {
    this.setState({
      displayDeleteConfirmation: !this.state.displayDeleteConfirmation,
      deletingStoryId: storyId
    });
  }

  handleDeleteClick = () => {

    // @todo Check for errors.

    const storyId = this.state.deletingStoryId;
    
    StoriesApi.deleteStory(storyId)
      .then((data) => {
        if (data.data.deleteStory.id) {
          
          let updatedStories = this.state.stories;
        
          updatedStories = updatedStories.filter((item) => {
            return storyId !== item.id;
          });

          this.setState({
            stories: updatedStories
          });
        }
        else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError('An error occured.');
      });

      this.toggleDeleteConfirmation();
  }

  reportError = (message) => {
    toast.error(message)
  }
}

export default Stories;
