import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Blocks from './Pages/Blocks/Blocks';
import Block from './Pages/Blocks/Block';
import EditPageImage from './Pages/EditPageImage';
import EditPageText from './Pages/EditPageText';
import FavoriteCreate from './Pages/Favorites/FavoriteCreate';
import FavoriteEdit1 from './Pages/Favorites/FavoriteEdit1';
import FavoriteEdit2 from './Pages/Favorites/FavoriteEdit2';
import Favorites from './Pages/Favorites/Favorites';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Stories from './Pages/Stories';
import StoryEdit1 from './Pages/StoryEdit1';
import StoryEdit2 from './Pages/StoryEdit2';
import StoryPreview from './Pages/StoryPreview';
import TipCreate from './Pages/Tips/TipCreate';
import TipEdit1 from './Pages/Tips/TipEdit1';
import TipEdit2 from './Pages/Tips/TipEdit2';
import Tips from './Pages/Tips/Tips';
import FrontpageEdit from './Pages/Frontpage/FrontpageEdit';
import FrontpagePreview from './Pages/Frontpage/FrontpagePreview';

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact={true} path="/" component={Home} />
            <Route path="/stories/" component={Stories} />
            <Route path="/favorites/" component={Favorites} />
            <Route path="/tips/" component={Tips} />
            <Route path="/login/" component={Login} />
            <Route exact={true} path="/blocks/" component={Blocks} />
            <Route path="/blocks/:id/" component={Block} />
            <Route exact={true} path="/frontpage/" component={FrontpageEdit} />
            <Route exact={true} path="/frontpage/preview/" component={FrontpagePreview} />
            <Route exact={true} path="/tip/" component={TipCreate} />
            <Route exact={true} path="/tip/:id/" component={TipEdit1} />
            <Route exact={true} path="/tip/:id/body/" component={TipEdit2} />
            <Route exact={true} path="/favorite/" component={FavoriteCreate} />
            <Route exact={true} path="/favorite/:id/" component={FavoriteEdit1} />
            <Route exact={true} path="/favorite/:id/body/" component={FavoriteEdit2} />
            <Route exact={true} path="/story/" component={StoryEdit1} />
            <Route exact={true} path="/story/:id/" component={StoryEdit1} />
            <Route exact={true} path="/story/:id/pages/" component={StoryEdit2} />
            <Route exact={true} path="/story/:id/pages/:pageId/" component={StoryEdit2} />
            <Route exact={true} path="/story/:id/pages/:pageId/image/" component={EditPageImage} />
            <Route path="/story/:id/pages/:pageId/image/:order" component={EditPageImage} />
            <Route path="/story/:id/pages/:pageId/text/" component={EditPageText} />
            <Route path="/story/:id/preview/" component={StoryPreview} />
          </Switch>
        </Router>

        <ToastContainer 
          autoClose={5000} 
          // position={toast.POSITION.TOP_CENTER}
          // hideProgressBar={true}
          // closeOnClick={true}
          // newestOnTop={true}
          // pauseOnHover={false}
          toastClassName="my-toast"
          bodyClassName="my-toast-body"
          progressClassName="my-toast-progress-bar"
        />
      </>
    );
  }
}

export default App;
