import React from "react";
import { formatDate, formatLocation, stripHtml } from '../Core/Utils';
import PropTypes from 'prop-types';

function CoverPageContent({ 
  title,
  subtitle,
  cover_title_size,
  cover_subtitle_size,
  published_at,
  city,
  country_name,
  onImageUpload
}) {
  return (
    <>
      <div className="title-and-subtitle" onClick={onImageUpload}>
        <div className="title">
          <div style={{fontSize: cover_title_size}}>{stripHtml(title)}</div>
        </div>
        {subtitle && 
          <div className="subtitle">
            <div style={{fontSize: cover_subtitle_size}}>{stripHtml(subtitle)}</div>
          </div>
        }
      </div>
      <div className="info py-1 px-3">
        <div className="d-flex flex-row justify-content-between details">
          <div className="location">{formatLocation(city, country_name)}</div>
          <div className="date">{formatDate(published_at)}</div>
        </div>
      </div>
    </>
  );
}

CoverPageContent.propTypes = {
  onImageUpload: PropTypes.func
}

CoverPageContent.defaultProps = {
  onImageUpload: null
};

/* only rerenders if props change */
export default React.memo(CoverPageContent);
