import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StoryPlayer from "../Components/StoryPlayer";
import PagesApi from "../Core/Api/PagesApi";
import Layout from "../Layout/Layout";
import styles from "./StoryPreview.module.scss";
import { ReactComponent as CloseIcon } from '../Icons/zondicons/close.svg';

class StoryPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id ? parseInt(props.match.params.id, 10) : null, 
      pages: []
    };
  }

  componentDidMount = () => {

    // If the story if new, don't try to load it from the remote source.
    if (!this.state.id) {
      return;
    }

    this.fetchPages(this.state.id);
  };

  render = () => {

    const headerStyles = {
      backgroundColor: '#000000',
      color: '#ffffff'
    }

    const {id, pages} = this.state;

    return (
      <Layout bg="dark">
        <header className="d-flex flex-row justify-content-end px-0 py-1 text-right top-menu" style={headerStyles}>
          <Link to={`/story/${id}/pages/`} style={{padding: '4px 14px'}}>
            <CloseIcon style={{fill: "#ffffff", width: "20px", height: "20px"}} />
          </Link>
        </header>
        
        <main className={styles.pages}>
          {pages.length > 0 &&
            <StoryPlayer pages={pages} id={id} topMargin={40} />
          }
        </main>
      </Layout>
    );
  };

  fetchPages = (storyId) => {
    PagesApi.fetchPages(storyId)
    .then(data => {
      if (data.errors) {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
      else if (data.data.pages && data.data.pages.length > 0) {
        this.setState({pages: data.data.pages});
      } 
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  reportError = message => {
    toast.error(message)
  };
}

export default StoryPreview;
