import { format, parse } from "date-fns";
import esLocale from "date-fns/locale/es";

export const stripHtml = (string = null) => {
  if (string === null) {
    return "";
  }
  return string.replace(/<[^>]+>/g, "");
}

export const formatLocation = (city, country_name) => {
  const parts = [];

  if (city) {
    parts.push(city);
  }
  if (country_name) {
    parts.push(country_name);
  }

  return parts.join(", ");
}

export const formatDate = dateString => {
  if (dateString == null) {
    return null;
  }

  const targetFormat = "d MMMM yyyy";

  const date = strToDate(dateString);

  return format(date, targetFormat, { locale: esLocale });
}

export const strToDate = string => {
  const serverDateFormat = "yyyy-MM-dd HH:mm:ss";
  return parse(string, serverDateFormat, new Date());
}

export const dateToStr = (date, customFormat = "yyyy-MM-dd HH:mm:ss") => {
  return format(date, customFormat);
}

export const groupItemsByRows = (items, numberPerRow = 3) => {
  let row = 0;
  let grouped = [];
  for(let i = 0; i < items.length; i++) {
    if (i % numberPerRow === 0) {
      row++;
      grouped[row] = [];
    }
    grouped[row].push(items[i]);
  }
  return grouped;
}

export const youtubeVideoId = (url) => {
  const match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
                url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (match) {
    return match[2];
  }
  
  return null;
}
