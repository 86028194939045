import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as UploadIcon } from '../../Icons/upload-icon.svg';

class ImageBoxView extends React.Component {

    static defaultProps = {
      image: null,
      editMode: true
    };
  
    static propTypes = {
      onImageUpload: PropTypes.func.isRequired,
      image: PropTypes.object,
      pageId: PropTypes.number.isRequired,
      editMode: PropTypes.bool
    };

    render() {
      const {image, editMode, onImageUpload} = this.props;

      let backgroundStyle = null;
      if (image) {
        backgroundStyle = {
          backgroundImage: `url(${image.url})`
        }
      }

      return (
        <>
          {editMode &&
            <div 
              className="img-container"
              style={backgroundStyle}
              onClick={onImageUpload}
            >
              {!image &&
                <div className="edit-button">
                  <UploadIcon />
                </div>
              }
            </div>
          }
          {!editMode &&
            <div 
              className="img-container" 
              style={backgroundStyle} 
            />
          }
        </>
      )
    }
}

export default ImageBoxView;
