import ApiBase from "./ApiBase";

export default class FavoritesApi extends ApiBase {
  static fetchFavorite = id => {
    const data = {
      query: `
            query favorites($id: Int!){
                favorites(id: $id){
                  id,
                  user {
                    name
                  },
                  title,
                  title_image,
                  title_image_original,
                  title_image_settings {
                    x,
                    y,
                    scale
                  },
                  body,
                  published_at,
                  categories {
                    id
                  }
                  country_code,
                  city,
                  status,
                  meta_description,
                  created_at
                }
            }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };

  static fetchFavorites = status => {
    const data = {
      query: `
            query favorites($status: Int!){
              favorites(status: $status){
                id,
                title,
                title_image,
                body,
                teaser,
                published_at,
                status,
                created_at,
                city,
                country_code,
                country_name,
                user {
                  name
                }
              }
            }
        `,
      variables: {
        status
      }
    };

    return this.authRequest(data);
  };

  static createFavorite = data => {
    const {
      title,
      body,
      country_code,
      city,
      meta_description,
      categories,
      published_at
    } = data;

    const operations = {
      query: `
          mutation createFavorite($title: String!, $body: String, $published_at: String!, $categories: [Int], $country_code: String, $city: String, $meta_description: String) {
            createFavorite(title: $title, body: $body, published_at: $published_at, categories: $categories, country_code: $country_code, city: $city, meta_description: $meta_description) {
              id,
              title,
              body,
              meta_description,
              published_at,
              status,
              created_at
            }
          }
        `,
      variables: {
        title,
        body,
        published_at,
        country_code,
        city,
        meta_description,
        categories
      }
    };

    return this.authRequest(operations);
  };

  static updateFavorite = data => {
    const {
      id,
      title,
      published_at,
      country_code,
      city,
      meta_description,
      categories
    } = data;

    const operations = {
      query: `
          mutation updateFavorite($id: Int!, $title: String!, $published_at: String!, $categories: [Int], $country_code: String, $city: String, $meta_description: String) {
            updateFavorite(id: $id, title: $title, published_at: $published_at, categories: $categories, country_code: $country_code, city: $city, meta_description: $meta_description) {
              id,
              title,
              meta_description,
              published_at
              updated_at
            }
          }
        `,
      variables: {
        id,
        title,
        published_at,
        country_code,
        city,
        meta_description,
        categories
      }
    };

    return this.authRequest(operations);
  };

  static updateFavoriteBody = data => {
    const { id, body } = data;

    const operations = {
      query: `
          mutation updateFavoriteBody($id: Int!, $body: String) {
            updateFavoriteBody(id: $id, body: $body) {
              id,
              body
            }
          }
        `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };

  static deleteFavorite = id => {
    const operations = {
      query: `
        mutation deleteFavorite($id: Int!) {
          deleteFavorite(id: $id){
            id
          }
        }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(operations);
  };

  static uploadFavoriteTitleImage = (favoriteId, imageFile, originalImageSettings, croppingRect) => {
    const operations = {
      query: `
          mutation uploadFavoriteTitleImage(
              $favoriteId: Int!, 
              $file: Upload!, 
              $imageSettingsInput: ImageSettingsInput!, 
              $imageCroppingRectInput: ImageCroppingRectInput!
          ) {
            uploadFavoriteTitleImage(
              favoriteId: $favoriteId, 
              file: $file, 
              imageSettingsInput: $imageSettingsInput, 
              imageCroppingRectInput: $imageCroppingRectInput
            ) {
                id,
                title_image,
                title_image_original,
                title_image_settings {
                    x,
                    y,
                    scale
                }
            }
          }
          `,
      variables: {
        favoriteId,
        file: null,
        imageSettingsInput: originalImageSettings,
        imageCroppingRectInput: croppingRect
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static uploadFavoriteContentImage = (favoriteId, imageFile) => {
    const operations = {
      query: `
          mutation uploadFavoriteContentImage(
              $favoriteId: Int!, 
              $file: Upload!
          ) {
            uploadFavoriteContentImage(
              favoriteId: $favoriteId, 
              file: $file
            ) {
                id,
                url
            }
          }
          `,
      variables: {
        favoriteId,
        file: null
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static publishFavorite = (id, body) => {
    const operations = {
      query: `
        mutation publishFavorite($id: Int!, $body: String) {
          publishFavorite(id: $id, body: $body) {
            status
          }
        }
      `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };
}
