import React from 'react';
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import ApiBase from '../Core/Api/ApiBase';
import Auth from '../Core/Auth';
import Layout from '../Layout/Layout';

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  render = () => {
    return (
      <Layout>
        <main className="compact-container d-flex flex-column justify-content-center full-height">
          <Form className="mx-auto w-100 p-4">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input 
                type="email" 
                name="email" 
                id="email" 
                placeholder="email@example.com" 
                value={this.state.email} 
                onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input 
                type="password" 
                name="password" 
                id="password" 
                placeholder="***" 
                value={this.state.password} 
                onChange={this.handleInputChange} />
            </FormGroup>
            <Button onClick={this.handleSubmit}>Login</Button>
          </Form>
        </main>
      </Layout>
    );
  }

  handleSubmit = (event) => {

    // @todo: Verify input params.

    ApiBase.logIn(this.state.email, this.state.password)
      .then((data) => {
        if (data.data.logIn && data.data.logIn.length > 0) {
          Auth.setAuthToken(data.data.logIn);
          this.props.history.push('/');
        }
        else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('Authentication error. Please check the login details.');
        }
      })
      .catch((err) => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError('An error occured.');
      });
  }

  reportError = (message) => {
    toast.error(message)
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
}

export default Login;
