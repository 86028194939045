import ApiBase from "./ApiBase";

export default class StoriesApi extends ApiBase {
  static fetchStories = status => {
    const data = {
      query: `
          query stories($status: Int!){
            stories(status: $status){
              id,
              title,
              subtitle,
              published_at,
              status,
              created_at,
              city,
              country_code,
              country_name,
              titlePages {
                id,
                images {
                  url
                }
              },
              cover_layout,
              cover_title_size,
              cover_subtitle_size
            }
          }
      `,
      variables: {
        status
      }
    };

    return this.authRequest(data);
  };

  static fetchStory = id => {
    const data = {
      query: `
          query stories($id: Int!){
              stories(id: $id){
                id,
                user {
                  name
                },
                title,
                subtitle,
                published_at,
                weight,
                categories {
                  id
                }
                country_code,
                city,
                meta_description,
                status,
                created_at,
                cover_layout,
                cover_title_size,
                cover_subtitle_size
              }
          }
      `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };

  static deleteStory = id => {
    const operations = {
      query: `
      mutation deleteStory($id: Int!) {
        deleteStory(id: $id){
          id
        }
      }
      `,
      variables: {
        id
      }
    };

    return this.authRequest(operations);
  };

  static createStory = data => {
    const {
      title,
      subtitle,
      country_code,
      city,
      categories,
      meta_description,
      published_at,
      weight,
      cover_layout,
      cover_title_size,
      cover_subtitle_size
    } = data;

    const operations = {
      query: `
        mutation createStory($title: String!, $subtitle: String, $published_at: String!, $weight: Int, $categories: [Int], $country_code: String, $city: String, $meta_description: String, $cover_layout: CoverLayout!, $cover_title_size: Int, $cover_subtitle_size: Int) {
          createStory(title: $title, subtitle: $subtitle, published_at: $published_at, weight: $weight, categories: $categories, country_code: $country_code, city: $city, meta_description: $meta_description, cover_layout: $cover_layout, cover_title_size: $cover_title_size, cover_subtitle_size: $cover_subtitle_size) {
            id,
            title,
            subtitle,
            meta_description,
            published_at,
            weight,
            status,
            created_at,
            cover_layout,
            cover_title_size,
            cover_subtitle_size
          }
        }
      `,
      variables: {
        title,
        subtitle,
        published_at,
        weight,
        country_code,
        city,
        meta_description,
        categories,
        cover_layout,
        cover_title_size,
        cover_subtitle_size
      }
    };

    return this.authRequest(operations);
  };

  static updateStory = data => {
    const {
      id,
      title,
      subtitle,
      published_at,
      weight,
      country_code,
      city,
      meta_description,
      categories,
      cover_layout,
      cover_title_size,
      cover_subtitle_size
    } = data;

    const operations = {
      query: `
        mutation updateStory($id: Int!, $title: String!, $subtitle: String, $published_at: String!, $weight: Int, $categories: [Int], $country_code: String, $city: String, $meta_description: String, $cover_layout: CoverLayout!, $cover_title_size: Int, $cover_subtitle_size: Int) {
          updateStory(id: $id, title: $title, subtitle: $subtitle, published_at: $published_at, weight: $weight, categories: $categories, country_code: $country_code, city: $city, meta_description: $meta_description, cover_layout: $cover_layout, cover_title_size: $cover_title_size, cover_subtitle_size: $cover_subtitle_size) {
            id,
            title,
            subtitle,
            meta_description,
            published_at,
            weight,
            updated_at,
            cover_layout,
            cover_title_size,
            cover_subtitle_size
          }
        }
      `,
      variables: {
        id,
        title,
        subtitle,
        published_at,
        weight,
        country_code,
        city,
        meta_description,
        categories,
        cover_layout,
        cover_title_size,
        cover_subtitle_size
      }
    };

    return this.authRequest(operations);
  };

  static publishStory = id => {

    const operations = {
      query: `
        mutation publishStory($id: Int!) {
          publishStory(id: $id) {
            status
          }
        }
      `,
      variables: {
        id
      }
    };

    return this.authRequest(operations);
  };

  static uploadStoryImage = (storyId, imageFile) => {
    const operations = {
      query: `
        mutation uploadStoryImage($storyId: Int!, $file: Upload!) {
          uploadStoryImage(storyId: $storyId, file: $file){
            id,
            name
          }
        }`,
      variables: {
        storyId,
        file: null
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static fetchAllStoryCategories = () => {
    const data = {
      query: `
            query publicStoryCategoryList($usedOnly: Boolean){
              publicStoryCategoryList(usedOnly: $usedOnly) {
                id,
                name
              }
            }
        `,
        variables: {
          usedOnly: false
        }
    };

    return this.request(data);
  };
}
