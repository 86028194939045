import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';

const Home = () => (
  <Layout>
    <main 
      className="my-5 mx-auto w-100 text-center d-flex flex-column justify-content-center align-items-center" 
      style={{maxWidth: "600px", height: "100%"}}
    >
      <div className="d-flex flex-column" style={{width: "80%"}}>
        <Link className="btn btn-lg btn-secondary btn-block my-2" to="/frontpage/">
          Frontpage
        </Link>
        <Link className="btn btn-lg btn-secondary btn-block my-2" to="/blocks/">
          Blocks
        </Link>
        <Link className="btn btn-lg btn-secondary btn-block my-2" to="/stories/">
          Stories
        </Link>
        <Link className="btn btn-lg btn-secondary btn-block my-2" to="/favorites/">
          Mis Favoritos
        </Link>
        <Link className="btn btn-lg btn-secondary btn-block my-2" to="/tips/">
          Consejos
        </Link>
      </div>
    </main>
  </Layout>
);

export default Home;
