class Auth {
    static isLoggedIn = () => {
        if (Auth.getAuthToken()) {
            return true;
        }
        return false;
    }

    static setAuthToken = (token) => {
        // window.authToken = token;
        localStorage.setItem('token', token);
    }

    static getAuthToken = () => {
        
        // const token = window.authToken;
        const token = localStorage.getItem('token');

        if (token === 'undefined') {
            return null;
        }
        
        return token || null;
    }

    static removeAuthToken() {
        // delete window.authToken;
        localStorage.removeItem('token');
    }
}

export default Auth;
