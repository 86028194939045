import ApiBase from "./ApiBase";

export default class PagesApi extends ApiBase {
  static createPage = data => {
    const { story_id, layout, body, image, order } = data;

    const operations = {
      query: `
          mutation createPage($story_id: Int!, $layout: Layout!, $body: String, $order: Int) {
            createPage(story_id: $story_id, layout: $layout, body: $body, order: $order) {
              id,
              story {
                id
              },
              layout,
              body,
              images {
                url
              },
              order
            }
          }
        `,
      variables: {
        story_id,
        layout,
        body,
        image,
        order
      }
    };

    return this.authRequest(operations);
  };

  static fetchPages = story_id => {
    const data = {
      query: `
            query pages($story_id: Int!){
                pages(story_id: $story_id){
                  id,
                  story {
                    id,
                    title,
                    subtitle,
                    published_at,
                    city,
                    country_name,
                    cover_layout,
                    cover_title_size,
                    cover_subtitle_size
                  },
                  layout,
                  body,
                  images {
                    id,
                    url
                  },
                  order
                }
            }
        `,
      variables: {
        story_id
      }
    };

    return this.authRequest(data);
  };

  static fetchPage = id => {
    const data = {
      query: `
          query pages($id: Int!){
            pages(id: $id){
              id,
              story {
                id
              },
              layout,
              body,
              images {
                id,
                url,
                url_original,
                order
              },
              order
            }
          }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };

  static uploadPageImage = (pageId, order, croppingRect, imageFile) => {
    const operations = {
      query: `
          mutation uploadPageImage($pageId: Int!, $order: Int!, $file: Upload!, $width: String!, $height: String!, $x: String!, $y: String!) {
            uploadPageImage(pageId: $pageId, order: $order, file: $file, width: $width, height: $height, x: $x, y: $y){
              id
            }
          }`,
      variables: {
        pageId,
        order,
        file: null,
        ...croppingRect
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static updatePageBody = data => {
    const { id, body } = data;

    const operations = {
      query: `
          mutation updatePage($id: Int!, $body: String) {
            updatePage(id: $id, body: $body) {
              id,
              body
            }
          }
        `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };

  static deletePage = id => {
    const operations = {
      query: `
        mutation deletePage($id: Int!) {
          deletePage(id: $id){
            id
          }
        }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(operations);
  };

  static orderPages = (storyId, order) => {
    const operations = {
      query: `
        mutation orderPages($id: Int!, $order: [Int]!) {
          orderPages(id: $id, order: $order){
            id,
            story {
              id
            },
            layout,
            body,
            images {
              url,
              order
            },
            order
          }
        }
        `,
      variables: {
        id: storyId,
        order
      }
    };

    return this.authRequest(operations);
  };

  static fetchPageImage = id => {
    const data = {
      query: `
          query pageImages($id: Int!){
            pageImages(id: $id){
              id,
              page { 
                layout
              },
              url,
              url_original,
              order
            }
          }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };
}
