import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import FormTopMenu from "../../Components/FormTopMenu";
import { editorAllowedFonts, editorAllowedFontSizes, editorAllowedLineHeights, editorAllowedTextColors } from "../../config";
import BlocksApi from "../../Core/Api/BlocksApi";
// We should register Quill pluging using their own way.
import '../../Core/Quill/blots/ResponsiveVideo';
import Layout from "../../Layout/Layout";
import "./Block.scss";

//~ Quill extension.

const icons = Quill.import("ui/icons");
icons["header"] = "H";
// @todo: Better import svg from 'quill/assets/icons/video.svg'.
icons["responsive-video"] = '<svg viewBox="0 0 18 18"><rect class="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="5" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="12" y="3"></rect> <rect class="ql-fill" height="2" width="8" x="5" y="8"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="12"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="12"></rect> </svg>';

const Delta = Quill.import("delta");
const matcher = (node, delta) => {
  return delta.compose(new Delta().retain(delta.length(), { header: 3 }));
};

// 17px is defaul font.
const allowedFontSizes = editorAllowedFontSizes;

const sizeStyle = Quill.import('attributors/style/size');
sizeStyle.whitelist = allowedFontSizes;
Quill.register(sizeStyle, true);

const allowedFonts = editorAllowedFonts;

const fontClass = Quill.import('attributors/class/font');
fontClass.whitelist = allowedFonts;
Quill.register(fontClass, true);

// Please note the integer data-value-s should be without float point. 
const allowedLineHeights = editorAllowedLineHeights;

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: allowedLineHeights
};
// const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
// Quill.register(lineHeightClass);
Quill.register(lineHeightStyle);

//~ Quill extension.

class Block extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      body: '',
      name: ''
    };

    this.id = props.match.params.id ? props.match.params.id : '';

    this.quill = null;
  }

  setRef = node => {
    if (node == null) {
      return;
    }

    this.quill = node;
  }
  
  componentDidMount = () => {

    // If the tip is new, don't try to load it from the remote source.
    if (!this.id) {
      return;
    }

    this.fetchBlock(this.id);    
  };

  render = () => {
    const { body } = this.state;

    return (
      <Layout bg="light">
        <FormTopMenu items={
          [
            {
              action: () => this.props.history.push('/blocks'),
              label: 'BACK'
            },
            {
              action: this.handleSaveClick,
              label: 'PUBLISH'
            }
          ]
        } />

        <main className="normal-container mb-5 mt-2">
          <ReactQuill
            theme="snow"
            modules={this.modules}
            formats={this.formats}
            placeholder="..."
            value={body}
            onChange={this.handleEditorChange}
            ref={this.setRef}
          />
        </main>
      </Layout>
    );
  };

  handleEditorChange = value => {
    this.setState({
      body: value
    });
  }

  handleSaveClick = () => {
    this.updateBlockBody(this.state);
  }

  updateBlockBody = (block) => {
    BlocksApi.updateBlockBody(block)
        .then(data => {
          if (data.data.updateBlockBody) {
            toast(`The "${block.name}" block has been updated successfully.`)

            this.props.history.push('/blocks');
          } else {
            // @todo: Handle errors better.
            console.warn(data);
            this.reportError("An error occured.");
          }
        })
        .catch(err => {
          // @todo: Handle errors better.
          console.warn(err);
          this.reportError("An error occured.");
        });
  }

  fetchBlock = (id) => {
    BlocksApi.fetchBlock(id)
    .then(data => {
      if (data.data.blocks && data.data.blocks.length > 0) {
        const block = data.data.blocks[0];
        this.setState(block);
      } else {
        // @todo: Handle errors better.
        console.warn(data);
        this.reportError("An error occured.");
      }
    })
    .catch(err => {
      // @todo: Handle errors better.
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  reportError = message => {
    toast.error(message)
  };

  modules = {
    toolbar: {
      container: [
        { 'color': editorAllowedTextColors },
        { 'font': allowedFonts },
        { 'size': allowedFontSizes },
        { 'lineheight': allowedLineHeights },
        { header: 3 },
        'bold',
        'italic',
        { list: 'ordered' },
        { list: 'bullet' },
        'link',
        'blockquote',
        'image',
        'responsive-video'
      ],
      handlers: {
        "responsive-video": function(value) {
          if (value) {
            let url = prompt("Please enter video url");
            if (url != null) {
              const range = this.quill.getSelection(true);
              // this.quill.insertText(range.index, "\n", Quill.sources.USER);
              this.quill.insertEmbed(range.index, "responsive-video", url, Quill.sources.USER);
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
          }
        },
        'image': this.imageHandler.bind(null, this)
      }
    },
    clipboard: {
      matchVisual: false, // https://quilljs.com/docs/modules/clipboard/#matchvisual
      matchers: [["h1", matcher], ["h2", matcher]]
    }
  };

  formats = [
    'color',
    'font',
    'size',
    'lineheight',
    'header',
    'bold',
    'italic',
    'link',
    'blockquote',
    'list',
    'image',
    'responsive-video'
  ];

  imageHandler(component) {

    const quill = component.quill;
    const quillEditor = quill.getEditor();
    const id = component.id;

    let fileInput = quillEditor.container.querySelector('input.ql-image[type=file]');
    if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/*');
        fileInput.classList.add('ql-image');
        fileInput.addEventListener('change', () => {
            const files = fileInput.files;
            const range =  quillEditor.getSelection(true);
            
            if (!files || !files.length) {
                toast.warn('No files selected');
                return;
            }

            quillEditor.enable(false);

            BlocksApi
              .uploadBlockContentImage(id, files[0])
              .then(data => {
                if (data.data.uploadBlockContentImage && data.data.uploadBlockContentImage.id) {
                  const image = data.data.uploadBlockContentImage;

                  quillEditor.enable(true);
                  quillEditor.insertEmbed(range.index, 'image', image.url);
                  quillEditor.insertText(range.index + 1, ' ', Quill.sources.SILENT);
                  quillEditor.setSelection(range.index + 2, Quill.sources.SILENT);
                  fileInput.value = '';
                } else {
                  console.warn(data);
                  component.reportError("An error occured.");
                  quillEditor.enable(true);
                }
              })
              .catch(err => {
                console.warn(err);
                component.reportError("An error occured.");
                quillEditor.enable(true);
              });
        });
        quillEditor.container.appendChild(fileInput);
    }
    fileInput.click();
  }
}

export default Block;
