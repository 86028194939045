import PropTypes from 'prop-types';
import React from 'react';
import { COVER_PAGE_LAYOUT } from '../config';
import Page from './Page';

const MODE_EDIT = 'edit';
const MODE_SORT = 'sort';

class PageList extends React.Component {

  static defaultProps = {
    pages: [],
    mode: MODE_EDIT
  };

  static propTypes = {
    pages: PropTypes.array,
    mode: PropTypes.oneOf([MODE_EDIT, MODE_SORT]),
    onDeleteCallback: PropTypes.func
  };

  render() {
      const {pages, storyId, mode, onDeleteCallback} = this.props;

      return (
        <>
          {pages.length > 0 && 
            pages.map((page) => 
              <div key={page.id} 
                className={'page-container ' + (page.layout === COVER_PAGE_LAYOUT ? 'sortable-ignore': '')}
                data-id={page.id}
              >
                <Page 
                  {...page} 
                  storyId={storyId} 
                  editMode={mode === MODE_EDIT} 
                  onDeleteCallback={onDeleteCallback}
                />
              </div>
          )}
        </>
      )
  }
}

export default PageList;
