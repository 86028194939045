import ApiBase from "./ApiBase";

export default class BlocksApi extends ApiBase {
  static fetchBlock = id => {
    const data = {
      query: `
            query blocks($id: Int!){
                blocks(id: $id){
                  id,
                  body,
                  name
                }
            }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };

  static fetchBlocks = status => {
    const data = {
      query: `
            query blocks {
              blocks {
                id,
                name
              }
            }
        `,
      variables: {
        status
      }
    };

    return this.authRequest(data);
  };

  static updateBlockBody = data => {
    const { id, body } = data;

    const operations = {
      query: `
          mutation updateBlockBody($id: Int!, $body: String) {
            updateBlockBody(id: $id, body: $body) {
              id,
              body
            }
          }
        `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };

  static uploadBlockContentImage = (blockId, imageFile) => {
    const operations = {
      query: `
          mutation uploadBlockContentImage(
              $blockId: Int!, 
              $file: Upload!
          ) {
            uploadBlockContentImage(
              blockId: $blockId, 
              file: $file
            ) {
                id,
                url
            }
          }
          `,
      variables: {
        blockId,
        file: null
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };
}
