import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ContentItemTeaser from '../../Components/ContentItemTeaser';
import { favoriteStatus } from '../../config';
import FavoritesApi from '../../Core/Api/FavoritesApi';
import Auth from '../../Core/Auth';
import { groupItemsByRows } from '../../Core/Utils';
import { ReactComponent as AddIcon } from '../../Icons/zondicons/add-outline.svg';
import Layout from '../../Layout/Layout';
import './Favorites.scss';

class Favorites extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      favorites: [],
      deletingFavoriteId: null,
      displayDeleteConfirmation: false,
      error: '',
      activeStatus: 0
    }
  }

  componentDidMount = () => {
    this.fetchFavorites(this.state.activeStatus);
  }

  render = () => {

    const {activeStatus, favorites, displayDeleteConfirmation} = this.state;

    const favoritesGroupedByRow = groupItemsByRows(favorites);

    return (
      <Layout>      
        <header>
          <div className="d-flex flex-row px-0 py-1 text-center top-menu">
            <Link to="/" className="w-50 py-2">
              HOME
            </Link>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === favoriteStatus.Draft ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, favoriteStatus.Draft)}>
              DRAFTS
            </button>
            <div className="d-flex flex-column flex-nowrap justify-content-center separator">
              <div className="bg" />
            </div>
            <button 
              className={activeStatus === favoriteStatus.Published ? 'w-50 py-2 active' : 'w-50 py-2'} 
              onClick={this.setActiveStatus.bind(null, favoriteStatus.Published)}>
              PUBLISHED
            </button>
          </div>
        </header>

        <main className="w-100 my-5 mx-auto">
          {favorites.length === 0 && 
              <div className="text-center">No favorites to display.</div>
          } 
          <div className="container-fluid favorites">
            {favoritesGroupedByRow.map((row, index) => (
              <div key={index} className="row justify-content-center">
                {row.map(favorite => (
                  <div key={favorite.id} className="col-sm col-sm-4">
                    <Link
                      className="favorite" 
                      to={'/favorite/' + favorite.id}
                      onClick={this.allowDeleteButtonClick}
                    >
                      <ContentItemTeaser
                        contentItem={favorite}
                        toggleDeleteConfirmation={this.toggleDeleteConfirmation.bind(null, favorite.id)} 
                      />
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <Modal isOpen={displayDeleteConfirmation} toggle={this.toggleDeleteConfirmation} fade={false} centered={true}>
            <ModalHeader toggle={this.toggleDeleteConfirmation}>Delete Confirmation</ModalHeader>
            <ModalBody>
              Do you really want to delete the favorite?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleDeleteClick}>Delete</Button>&nbsp;
              <Button color="secondary" onClick={this.toggleDeleteConfirmation}>Cancel</Button>
            </ModalFooter>
          </Modal>

          <footer className="bottom-bar buttonless">
            <Link className="add-button" to="/favorite/">
              <AddIcon />
            </Link>
          </footer>
        </main>
      </Layout>
    );
  }

  allowDeleteButtonClick = e => {
    
    if(e.target.classList.contains('delete-btn')) {
      e.preventDefault();
    }
  }

  fetchFavorites = (status) => {
    FavoritesApi.fetchFavorites(status)
      .then(data => {
        if (data.data.favorites) {
          this.setState({
            favorites: data.data.favorites
          });
        }
        else {
          // @todo Report user error.
          if (data.errors) {
            data.errors.forEach(item => {
              if (item.message === 'Unauthorized') {
                Auth.removeAuthToken();
                this.props.history.push('/login/');
                return;
              }
            });
          }
          
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        console.warn(err);

        // @todo: Handle errors better.
        this.reportError('An error occured.');
      });
  }

  setActiveStatus = (status) => {
    if (status === this.state.activeStatus) {
      return;
    }

    this.setState({
      'activeStatus': status
    });

    this.fetchFavorites(status);
  }

  toggleDeleteConfirmation = (favoriteId) => {
    this.setState({
      displayDeleteConfirmation: !this.state.displayDeleteConfirmation,
      deletingFavoriteId: favoriteId
    });
  }

  handleDeleteClick = () => {

    // @todo Check for errors.

    const favoriteId = this.state.deletingFavoriteId;
    
    FavoritesApi.deleteFavorite(favoriteId)
      .then((data) => {
        if (data.data.deleteFavorite.id) {
          
          let updatedFavorites = this.state.favorites;
        
          updatedFavorites = updatedFavorites.filter((item) => {
            return favoriteId !== item.id;
          });

          this.setState({
            favorites: updatedFavorites
          });
        }
        else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError('An error occured.');
      });

      this.toggleDeleteConfirmation();
  }

  reportError = (message) => {
    toast.error(message)
  }
}

export default Favorites;
