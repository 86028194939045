import loadImage from 'blueimp-load-image';
import { PropTypes } from 'prop-types';
import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { toast } from "react-toastify";
import { Spinner } from 'reactstrap';
import { ReactComponent as AddIcon } from '../Icons/zondicons/add-outline.svg';
import { ReactComponent as PhotoIcon } from '../Icons/zondicons/photo.svg';
import { ReactComponent as RefreshIcon } from '../Icons/zondicons/refresh.svg';
import styles from './SimpleImageUploadControl.module.scss';

const DEFAULT_SCALE = 1;
const EDITOR_SIZE_DIVIDER = 2;

/**
 * SimpleImageUploadControl component is used to upload image 
 * and doesn't support editing already uploaded image.
 * 
 * @see ImageUploadControl
 */
class SimpleImageUploadControl extends React.Component {

  static defaultProps = {
    editorWidth: 370 / EDITOR_SIZE_DIVIDER,
    editorHeight: 520 / EDITOR_SIZE_DIVIDER,
  }

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    editorWidth: PropTypes.number.isRequired,
    editorHeight: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      hasChanges: false,
      uploadedOriginalFile: null,
      image: null,
      position: {
        x: 0,
        y: 0,
      },
      scale: DEFAULT_SCALE,
      selecting: false,
      loadingFromProps: false
    }

    // this.imageMime = 'image/png';
    // this.imageQuality = DEFAULT_IMAGE_QUALITY;
    this.fileUploadInput = null;
  }

  render = () => {
    const {
      image,
      selecting,
      scale,
      position,
      loadingFromProps,
      hasChanges
    } = this.state;

    const {editorWidth, editorHeight} = this.props;

    return (
      <div className={styles['image-upload-control']}>
        {loadingFromProps && 
          <p className="m-3 text-center text-muted">Loading...</p>}

        {!loadingFromProps &&
            <div className="d-flex flex-column justify-content-center mx-auto text-center">

              <div className={styles['buttons']}>
                <button className={styles['top-button']} onClick={this.handleSelectLocalImage}>
                  <AddIcon />
                </button>
                <button className={styles['top-button']} onClick={this.handleResetToOriginal}>
                  <RefreshIcon />
                </button>
              </div>
        
              <div className={styles['image-container']}>
                <AvatarEditor
                  image={image}
                  crossOrigin="anonymous"
                  width={editorWidth}
                  height={editorHeight}
                  border={15}
                  color={[150, 150, 150, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                  ref={this.setEditorRef}
                  position={position}
                  onPositionChange={this.handlePositionChange}
                  className="image-editor"
                  onLoadFailure={this.handleLoadFailure}
                />
                {!image && !selecting &&
                  <div className={styles['edit-button']}>
                    <PhotoIcon />
                  </div>
                }
                {selecting && 
                  <Spinner size="lg" color="secondary" className={styles['my-spinner']} /> }
              </div>

              <input 
                type="file" 
                accept="image/*"
                style={{display: "none"}} 
                ref={input => this.fileUploadInput = input}
                onChange={this.handleFileChange}
              />
      
              <input style={{width: '260px'}}
                name="scale"
                type="range"
                onChange={this.handleScale}
                min="0.5"
                max="2"
                step="0.01"
                value={scale}
                className="mx-auto mt-3"
              />

              <br/>
              <button 
                className="btn btn-primary mx-auto" 
                onClick={this.handleSaveImageClick} 
                disabled={!hasChanges}>
                Upload
              </button>
            </div>
          }
      </div>
    );
  }

  setEditorRef = (editor) => this.editor = editor

  handlePositionChange = position => {
    this.setState({ 
      position,
      hasChanges: true
    })
  }

  handleSaveImageClick = (e) => {
    e.preventDefault();

    const {image, uploadedOriginalFile} = this.state;
    const {onSave} = this.props;

    if (image === null) {
      this.reportError("Please select an image first.");
      return false;
    }

    // Everthing we get from AvatarEditor is the cropping rectangle 
    // because we do image processing at the backend level.
    const croppingRect = this.editor.getCroppingRect();
    // const canvas = this.editor.getImage();
    // const canvas = this.editor.getImageScaledToCanvas();

    // this.setState({processedImage: canvas.toDataURL()});

    // If user just uploaded an image, we're sending it to the backed 
    // with the croppign react and original image settings.
    onSave.call(null, uploadedOriginalFile, croppingRect);
    this.setState({hasChanges: false});
  }

  handleLoadFailure = (e) => {
    console.error(e);
    toast.error('Could not load the image.');
  }

  handleScale = e => {
    this.setState({ 
      scale: parseFloat(e.target.value),
      hasChanges: true
    });
  }

  handleFileChange = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    this.setState({
      selecting: true, 
      uploadedOriginalFile: file,
      hasChanges: true
    });

    // file type is only image.
    if (/^image\//.test(file.type)) {

      // Fix orientation if necessary.
      const loadingImage = loadImage(
          file,
          (canvas) => {
            if(canvas.type === "error") {
              toast.error("An error occurred while loading the image: " + canvas.message);
            } else {
              // Pass the resulted image to the AvatarEditor for further manipulations.
              this.setState({
                image: canvas.toDataURL(),
                scale: DEFAULT_SCALE
              });

              this.setState({selecting: false});
              // toast.success('Your image has been loaded. Now you may adapt it.');
            }
          },
          {
              orientation: true,
              // Return result as convas.
              canvas: true
          }
      );

      if (!loadingImage) {
        toast.error('Image processing error');
        return false;
      }

    } else {
      toast.warn('You can only upload images.');
    }
  }

  handleResetToOriginal = (e) => {
    e.preventDefault();

    // Need to be able to upload the same image after restet.
    this.fileUploadInput.value = null;

    this.setState({ 
      image: null,
      scale: DEFAULT_SCALE,
      position: {
        x: 0,
        y: 0
      },
      hasChanges: true
    });
  }

  handleSelectLocalImage = (e) => {
    e.preventDefault();

    if (!this.fileUploadInput) {
      return;
    }

    // Trigger click on the hidden file input.
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', false, true, window);  
    this.fileUploadInput.dispatchEvent(event);
  }

  reportError = (message) => {
    console.error(message);
    toast.error(message)
  }
}

export default SimpleImageUploadControl;
