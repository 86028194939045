import React from 'react';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import PagesApi from '../Core/Api/PagesApi';
import { editorAllowedLineHeights, editorAllowedFonts, editorAllowedFontSizes, editorAllowedTextColors } from "../config";
// We should register Quill pluging using their own way.
import '../Core/Quill/blots/ResponsiveVideo';
import Layout from '../Layout/Layout';
import './EditPageText.scss';

//~ Quill extension.

const icons = Quill.import("ui/icons");
icons["header"] = "H";
// @todo: Better import svg from 'quill/assets/icons/video.svg'.
icons["responsive-video"] = '<svg viewBox="0 0 18 18"><rect class="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="5" y="3"></rect> <rect class="ql-fill" height="12" width="1" x="12" y="3"></rect> <rect class="ql-fill" height="2" width="8" x="5" y="8"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="3" y="12"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="5"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="7"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="10"></rect> <rect class="ql-fill" height="1" width="3" x="12" y="12"></rect> </svg>';

const Delta = Quill.import("delta");
const matcher = (node, delta) => {
  return delta.compose(new Delta().retain(delta.length(), { header: 3 }));
};

// 17px is defaul font.
const allowedFontSizes = editorAllowedFontSizes;

const sizeStyle = Quill.import('attributors/style/size');
sizeStyle.whitelist = allowedFontSizes;
Quill.register(sizeStyle, true);

const allowedFonts = editorAllowedFonts;

const fontClass = Quill.import('attributors/class/font');
fontClass.whitelist = allowedFonts;
Quill.register(fontClass, true);

// Please note the integer data-value-s should be without float point. 
const allowedLineHeights = editorAllowedLineHeights;

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: allowedLineHeights
};
// const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
// Quill.register(lineHeightClass);
Quill.register(lineHeightStyle);

//~ Quill extension.

class EditPageText extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id ? props.match.params.id : '',
      pageId: props.match.params.pageId ? props.match.params.pageId : '',
      body: null,
      isPageLoaded: false
    }

    // @todo: Raise an error if pageId is not set!
  }

  componentDidMount = () => {

    // Just in case, check if the page is passed through the url.
    if (!this.state.pageId) {
      return;
    }

    this.fetchPage(this.state.pageId);
  };

  render = () => {
    return (
      <Layout bg="light">
        <header>
          <div className="d-flex flex-row justify-content-between px-0 py-1 text-center top-menu">
            <Link to={`/story/${this.state.id}/pages/${this.state.pageId}`}>BACK</Link>
            <button className="text-primary" onClick={this.saveTextClick} disabled={!this.state.isPageLoaded}>
              SAVE
            </button>
          </div>
        </header>

        {!this.state.isPageLoaded && 
          <p className="m-3 text-center text-muted">Loading...</p>}

        {this.state.isPageLoaded &&
          <div className="mx-auto w-100 my-3 px-3 editor-container">
            <ReactQuill
              theme="snow"
              modules={this.modules}
              formats={this.formats}
              placeholder="..."
              value={this.state.body}
              onChange={this.handleEditorChange}
              ref={ref => this.q = ref}
              className="page-editor"
            />
          </div>}
      </Layout>
    );
  }

  fetchPage = (pageId) => {
    PagesApi.fetchPage(pageId)
    .then(data => {
      if (data.errors) {
        console.warn(data);
        this.reportError("An error occured.");
      }
      else if (data.data.pages && data.data.pages.length > 0) {
        const page = data.data.pages[0];

        this.setState({
          body: page.body,
          isPageLoaded: true
        });
      } 
    })
    .catch(err => {
      console.warn(err);
      this.reportError("An error occured.");
    });
  }

  saveTextClick = () => {
    this.updatePageBody({
      id: this.state.pageId,
      body: this.state.body
    });
  }

  updatePageBody = (page) => {
    PagesApi.updatePageBody(page)
        .then(data => {
          if (data.errors) {
            console.error(data);
            this.reportError("An error occured.");
          }
          else if (data.data.updatePage) {
            const page = data.data.updatePage;
            
            this.setState({
              body: page
            });

            this.props.history.push(`/story/${this.state.id}/pages/${this.state.pageId}/`);
          }
        })
        .catch(err => {
          console.warn(err);
          this.reportError("An error occured.");
        });
  }

  handleEditorChange = value => {
    this.setState({
      body: value
    });
  }

  reportError = message => {
    console.error(message);
    toast.error(message)
  }

  modules = {
    toolbar: {
      container: [
        { 'color': editorAllowedTextColors },
        { 'font': allowedFonts },
        { 'size': allowedFontSizes },
        { 'lineheight': allowedLineHeights },
        { header: 3 },
        'bold',
        'italic',
        { list: 'ordered' },
        { list: 'bullet' },
        'link',
        'blockquote',
        'responsive-video'
      ],
      handlers: {
        "responsive-video": function(value) {
          if (value) {
            let url = prompt("Please enter video url");
            if (url != null) {
              const range = this.quill.getSelection(true);
              // this.quill.insertText(range.index, "\n", Quill.sources.USER);
              this.quill.insertEmbed(range.index, "responsive-video", url, Quill.sources.USER);
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
          }
        }
      }
    },
    clipboard: {
      matchVisual: false, // https://quilljs.com/docs/modules/clipboard/#matchvisual
      matchers: [["h1", matcher], ["h2", matcher]]
    }
  };

  formats = [
    'color',
    'font',
    'size',
    'lineheight',
    'header',
    'bold',
    'italic',
    'link',
    'blockquote',
    'list',
    'responsive-video'
  ];
}

export default EditPageText;
