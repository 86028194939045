import ApiBase from "./ApiBase";

export default class FrontpageApi extends ApiBase {
  static uploadFrontpageCategoryImage = (imageFile, croppingRect) => {
    const operations = {
      query: `
          mutation uploadFrontpageCategoryImage(
              $file: Upload!, 
              $imageCroppingRectInput: ImageCroppingRectInput!
          ) {
              uploadFrontpageCategoryImage(
                  file: $file, 
                  imageCroppingRectInput: $imageCroppingRectInput
              )
          }
          `,
      variables: {
        file: null,
        imageCroppingRectInput: croppingRect
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  }

  static uploadFrontpageBgImage = (imageFile, croppingRect) => {
    const operations = {
      query: `
          mutation uploadFrontpageBgImage(
              $file: Upload!, 
              $imageCroppingRectInput: ImageCroppingRectInput!
          ) {
              uploadFrontpageBgImage(
                  file: $file, 
                  imageCroppingRectInput: $imageCroppingRectInput
              )
          }
          `,
      variables: {
        file: null,
        imageCroppingRectInput: croppingRect
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  }
}
