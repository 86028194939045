import React from "react";
import { withRouter } from 'react-router-dom';
import AuthenticationRequired from "../Components/AuthenticationRequired";
import Auth from '../Core/Auth';
import './Layout.scss';

class Layout extends React.Component {

  checkLocalAuth() {
    if (!Auth.isLoggedIn() && this.props.location.pathname !== '/login/') {
      return false;
      // return this.props.history.push('/login/');
    }
    return true;
  }

  render = () => {
    const {bg, children} = this.props;
    return (
      <>
        <AuthenticationRequired />
        {this.checkLocalAuth() && 
          <div className={`layout ${bg}`}>  
            {children}
          </div>
        }
      </>
    );
  };
}

export default withRouter(Layout);
