import PropTypes from 'prop-types';
import React from "react";
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { coverPageLayoutTypes, layoutTypes, COVER_PAGE_LAYOUT } from '../config';
import PagesApi from '../Core/Api/PagesApi';
import { ReactComponent as TrashIcon } from '../Icons/zondicons/trash.svg';
import CoverPageContent from './CoverPageContent';
import './Page.scss';
import ImageBoxView from './PageLayouts/ImageBoxView';
import TextBoxView from './PageLayouts/TextBoxView';

class Page extends React.Component {

  static propTypes = {
    // page.id
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired, // if went from the router. @todo get rid of it.
      PropTypes.number.isRequired
    ]),
    // story.id
    storyId: PropTypes.oneOfType([
      PropTypes.string.isRequired, // if went from the router. @todo get rid of it.
      PropTypes.number.isRequired
    ]),
    story: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      published_at: PropTypes.string,
      city: PropTypes.string,
      country_name: PropTypes.string,
      cover_layout: PropTypes.oneOf(coverPageLayoutTypes().map(layout => layout.name)),
      cover_title_size: PropTypes.number,
      cover_subtitle_size: PropTypes.number
    }),
    layout: PropTypes.oneOf(layoutTypes().map(layout => layout.name)),
    body: PropTypes.string,
    images: PropTypes.array,
    order: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    editMode: PropTypes.bool,
    onDeleteCallback: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      displayDeleteConfirmation: false
    }
  }

  render() {

    const {
      width,
      height,
      editMode,
      body,
      id,
      images,
      layout,
      story
    } = this.props;

    return (
      <div className="page" style={{width, height}}>
        <div className={'layout-wrapper ' + layout + (layout === COVER_PAGE_LAYOUT ? ' ' + story.cover_layout : '')}>
          {editMode && layout !== COVER_PAGE_LAYOUT &&
            <div className="controls d-flex flex-row justify-content-end">
              <button onClick={this.toggleDeleteConfirmation} className="delete-btn">
                <TrashIcon />
              </button>
            </div>
          }

          {layout === COVER_PAGE_LAYOUT &&
            <>
              <ImageBoxView 
                onImageUpload={this.handleEditImageClick.bind(null, 1)} 
                image={images[0]} 
                pageId={id}
                editMode={editMode}
              />
              <CoverPageContent {...story} 
                onImageUpload={editMode ? this.handleEditImageClick.bind(null, 1) : null} 
              />
            </>
          }

          {layout === 'TEXT' &&
            <TextBoxView 
              onTextEdit={this.handleEditTextClick} 
              body={body} 
              editMode={editMode}
            />
          }
          {(layout === 'IMAGE' || layout === 'FRAMED_IMAGE') &&
            <ImageBoxView 
              onImageUpload={this.handleEditImageClick.bind(null, 1)} 
              image={images[0]} 
              pageId={id}
              editMode={editMode}
            />
          }
          {(
            layout === 'IMAGE_AND_TEXT' || 
            layout === 'FRAMED_IMAGE_AND_TEXT' || 
            layout === 'IMAGE_WITH_TITLE' ||
            layout === 'QUOTE'
            ) &&
            <>
              <ImageBoxView 
                onImageUpload={this.handleEditImageClick.bind(null, 1)} 
                image={images[0]} 
                pageId={id}
                editMode={editMode}
              />
              <TextBoxView 
                onTextEdit={this.handleEditTextClick} 
                body={body} 
                editMode={editMode}
              />
            </>
          }
          {(layout === 'TWO_IMAGES' || layout === 'TWO_FRAMED_IMAGES') &&
            <>
              <ImageBoxView 
                onImageUpload={this.handleEditImageClick.bind(null, 1)} 
                image={images[0]} 
                pageId={id}
                editMode={editMode}
              />
              <ImageBoxView 
                onImageUpload={this.handleEditImageClick.bind(null, 2)} 
                image={images[1]} 
                pageId={id}
                editMode={editMode}
              />
            </>
          }
        </div>

        {editMode &&
          <Modal 
            isOpen={this.state.displayDeleteConfirmation} 
            toggle={this.toggleDeleteConfirmation} 
            fade={false} 
            centered={true}
          >
            <ModalHeader toggle={this.toggleDeleteConfirmation}>Delete Confirmation</ModalHeader>
            <ModalBody>
              Do you really want to delete the page?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleDeleteClick}>Delete</Button>&nbsp;
              <Button color="secondary" onClick={this.toggleDeleteConfirmation}>Cancel</Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    );
  }

  handleEditImageClick = (order) => {
    const {storyId, id} = this.props;

    this.props.history.push(`/story/${storyId}/pages/${id}/image/${order}`);
  }

  handleEditTextClick = () => {
    const {storyId, id} = this.props;

    this.props.history.push(`/story/${storyId}/pages/${id}/text/`);
  }

  toggleDeleteConfirmation = () => {
    this.setState({
      displayDeleteConfirmation: !this.state.displayDeleteConfirmation
    });
  }

  handleDeleteClick = () => {
    // @todo Check for errors.

    const pageId = this.props.id;
    
    PagesApi.deletePage(pageId)
      .then((data) => {
        if (data.data.deletePage.id) {
          if (this.props.onDeleteCallback) {
            this.props.onDeleteCallback(data.data.deletePage.id);
          }
        }
        else {
          // @todo: Handle errors better.
          console.warn(data);
          this.reportError('An error occured.');
        }
      })
      .catch((err) => {
        // @todo: Handle errors better.
        console.warn(err);
        this.reportError('An error occured.');
      });

      this.toggleDeleteConfirmation();
  }

  reportError = message => {
    toast.error(message)
  };
}

export default withRouter(Page);
