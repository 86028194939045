import React from "react";
import { PropTypes } from 'prop-types';
import styles from './FormTopMenu.module.scss';

const FormHeader = props =>  
  <header>
  <div className={styles['top-menu']}>
    {props.items.map(item => 
      item != null ? <button key={item.label} onClick={item.action}>
        {item.label}
      </button> : null
    )}
  </div>
  </header>

FormHeader.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string
  })).isRequired
}

export default React.memo(FormHeader);
