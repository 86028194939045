import ApiBase from "./ApiBase";

export default class VariablesApi extends ApiBase {
  static getVariable = name => {
    const data = {
      query: `
            query variable($name: String!){
                variable(name: $name){
                  value
                }
            }
        `,
      variables: {
        name
      }
    };

    return this.request(data);
  };

  static setVariable = (name, value) => {
    const operations = {
      query: `
          mutation setVariable($name: String!, $value: String) {
            setVariable(name: $name, value: $value) {
              name,
              value
            }
          }
        `,
      variables: {
        name,
        value
      }
    };

    return this.authRequest(operations);
  };
}
