import ApiBase from "./ApiBase";

export default class TipsApi extends ApiBase {
  static fetchTip = id => {
    const data = {
      query: `
            query tips($id: Int!){
                tips(id: $id){
                  id,
                  user {
                    name
                  },
                  title,
                  title_image,
                  title_image_original,
                  title_image_settings {
                    x,
                    y,
                    scale
                  },
                  body,
                  meta_description,
                  published_at,
                  country_code,
                  city,
                  status,
                  created_at
                }
            }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(data);
  };

  static fetchTips = status => {
    const data = {
      query: `
            query tips($status: Int!){
              tips(status: $status){
                id,
                title,
                title_image,
                body,
                teaser,
                published_at,
                status,
                created_at,
                city,
                country_code,
                country_name,
                user {
                  name
                }
              }
            }
        `,
      variables: {
        status
      }
    };

    return this.authRequest(data);
  };

  static createTip = data => {
    const {
      title,
      body,
      country_code,
      city,
      meta_description,
      published_at
    } = data;

    const operations = {
      query: `
          mutation createTip($title: String!, $body: String, $published_at: String!, $country_code: String, $city: String, $meta_description: String) {
            createTip(title: $title, body: $body, published_at: $published_at, country_code: $country_code, city: $city, meta_description: $meta_description) {
              id,
              title,
              body,
              meta_description,
              published_at,
              status,
              created_at
            }
          }
        `,
      variables: {
        title,
        body,
        country_code,
        city,
        meta_description,
        published_at
      }
    };

    return this.authRequest(operations);
  };

  static updateTip = data => {
    const {
      id,
      title,
      published_at,
      country_code,
      city,
      meta_description
    } = data;

    const operations = {
      query: `
          mutation updateTip($id: Int!, $title: String!, $published_at: String!, $country_code: String, $city: String, $meta_description: String) {
            updateTip(id: $id, title: $title, published_at: $published_at, country_code: $country_code, city: $city, meta_description: $meta_description) {
              id,
              title,
              meta_description,
              published_at
              updated_at
            }
          }
        `,
      variables: {
        id,
        title,
        published_at,
        country_code,
        city,
        meta_description
      }
    };

    return this.authRequest(operations);
  };

  static updateTipBody = data => {
    const { id, body } = data;

    const operations = {
      query: `
          mutation updateTipBody($id: Int!, $body: String) {
            updateTipBody(id: $id, body: $body) {
              id,
              body
            }
          }
        `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };

  static deleteTip = id => {
    const operations = {
      query: `
        mutation deleteTip($id: Int!) {
          deleteTip(id: $id){
            id
          }
        }
        `,
      variables: {
        id
      }
    };

    return this.authRequest(operations);
  };

  static uploadTipTitleImage = (tipId, imageFile, originalImageSettings, croppingRect) => {
    const operations = {
      query: `
          mutation uploadTipTitleImage(
              $tipId: Int!, 
              $file: Upload!, 
              $imageSettingsInput: ImageSettingsInput!, 
              $imageCroppingRectInput: ImageCroppingRectInput!
          ) {
            uploadTipTitleImage(
              tipId: $tipId, 
              file: $file, 
              imageSettingsInput: $imageSettingsInput, 
              imageCroppingRectInput: $imageCroppingRectInput
            ) {
                id,
                title_image,
                title_image_original,
                title_image_settings {
                    x,
                    y,
                    scale
                }
            }
          }
          `,
      variables: {
        tipId,
        file: null,
        imageSettingsInput: originalImageSettings,
        imageCroppingRectInput: croppingRect
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static uploadTipContentImage = (tipId, imageFile) => {
    const operations = {
      query: `
          mutation uploadTipContentImage(
              $tipId: Int!, 
              $file: Upload!
          ) {
            uploadTipContentImage(
              tipId: $tipId, 
              file: $file
            ) {
                id,
                url
            }
          }
          `,
      variables: {
        tipId,
        file: null
      }
    };

    var data = new FormData();
    data.append("operations", JSON.stringify(operations));
    data.append(
      "map",
      JSON.stringify({
        0: ["variables.file"]
      })
    );
    data.append(0, imageFile);

    return this.authUploadRequest(data);
  };

  static publishTip = (id, body) => {
    const operations = {
      query: `
        mutation publishTip($id: Int!, $body: String) {
          publishTip(id: $id, body: $body) {
            status
          }
        }
      `,
      variables: {
        id,
        body
      }
    };

    return this.authRequest(operations);
  };
}
